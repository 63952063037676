import React from 'react';

function FooterMobile() {
    return (
        <div className='footer rounded-md shadow'>
            <footer className="bg-accent-20 w-full mt-12">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <a href="https://supergreeter.co/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <img src="https://supergreeter-product-resources.s3.amazonaws.com/companyName/email-resources/SuperGreeter-hands-sml.png" class="h-8" alt="SuperGreeter Hands Logo" />
                            <span className="self-center text-5 text-skin-muted font-semibold whitespace-nowrap">SuperGreeter</span>
                        </a>
                        <ul className="flex flex-wrap gap-4 mb-6 text-3 font-medium sm:mb-0 sm:mt-4 justify-evenly min-w-80 text-skin-muted">
                            <li className='flex'>
                                <a href="https://supergreeter.co" className="hover:underline md:me-6">About</a>
                            </li>
                            <li className='flex hidden'>
                                <a href="#" className="hover:underline md:me-6">Privacy Policy</a>
                            </li>
                            <li className='flex'>
                                <a href="#" className="hover:underline md:me-6">Partners</a>
                            </li>
                            <li className='flex'>
                                <a href="https://www.supergreeter.co/get-started" className="hover:underline md:me-6">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <hr className="my-6 border-gray-300 sm:mx-auto lg:my-8" />
                    <span className="block text-sm text-gray-500 sm:text-center space-y-2">© 2024 <a href="https://supergreeter.co/" class="hover:underline">SuperGreeter, Inc.</a></span>
                </div>
            </footer>
        </div>
    )

}

export default FooterMobile;
