import React, { useState } from 'react';
import './Register.css';
import axios from 'axios';
import config from 'config';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from '../../contexts/UserContext';

function Register() {
    const [company, setCompany] = useState({
        email: '',
        password: '',
        name: '',
    });

    const [imagePreviewUrl, setImagePreviewUrl] = useState('');

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const { login } = useContext(UserContext);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setCompany(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));

        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: null
            }));
        }
    };

    const handleFileChange = async (e) => {
        e.preventDefault();

        let reader = new FileReader();
        const name = e.target.name;
        const file = e.target.files[0];

        if (file && file.type.match('image.*')) {
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
                setCompany(prev => ({
                    ...prev,
                    [name]: reader.result
                }));
            };

            reader.readAsDataURL(file);
        }
    };

    const validateForm = () => {
        let newErrors = {};
        // Email validation
        if (!company.email || !company.email.includes('@')) {
            newErrors.email = 'Please enter a valid email address.';
        }

        // Password validation
        if (!company.password || company.password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters long.';
        }
        // Company name validation
        if (!company.name) {
            newErrors.name = 'Company Name is required.';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            try {
                // First register the user
                const registerResponse = await axios.post(`${config.apiEndpoint}/create`, company);
                const { validationErrors } = registerResponse.data;
                
                if (validationErrors && validationErrors.length > 0) {
                    validationErrors.forEach(errorObject => {
                        for (let [field, message] of Object.entries(errorObject)) {
                            formErrors[field] = message
                        }
                    })
                    setErrors(formErrors);
                } else {
                    // Then log them in
                    const loginResponse = await axios.post(`${config.apiEndpoint}/login`, {
                        email: company.email,
                        password: company.password
                    });
                    
                    const { token } = loginResponse.data;
                    login(token);
                    navigate('/admin/welcome');
                }
            } catch (error) {
                setErrors({
                    submit: 'An error occurred during registration. Please try again.'
                });
            }
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="max-w-lg mx-auto">
                <img
                    alt="SuperGreeter"
                    src="https://supergreeter-product-resources.s3.amazonaws.com/companyName/email-resources/SuperGreeter-logo-overhang.png"
                    className="mx-auto max-h-24"
                />
                <h1 className='mt-10 text-center text-3xl font-bold tracking-tight text-gray-900'>Create your digital amenities store</h1>
                <h2 className="mt-10 text-center text-lg font-medium leading-9 tracking-tight text-muted-foreground">
                    Please register with your company's information.
                </h2>
            </div>

            <div className="mt-10 mx-auto max-w-lg">
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Company Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                autoComplete="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-70 sm:text-sm sm:leading-6"
                                value={company.email}
                                onChange={handleChange}
                            />
                            {errors.email && (
                                <div className="text-red-500 text-sm mt-1">
                                    {errors.email}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Password
                        </label>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                autoComplete="current-password"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-80 sm:text-sm sm:leading-6"
                                value={company.password}
                                onChange={handleChange}
                            />
                            {errors.password && (
                                <div className="text-red-500 text-sm mt-1">
                                    {errors.password}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                            Company Name
                        </label>
                        <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-70 sm:text-sm sm:leading-6"
                                value={company.name}
                                onChange={handleChange}
                            />
                            {errors.name && (
                                <div className="text-red-500 text-sm mt-1">
                                    {errors.name}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
                            Company Logo
                        </label>
                        <div className="mt-2">
                            <input
                                type="file"
                                id="file"
                                name="file"
                                className="block w-full text-gray-900 sm:text-sm sm:leading-6"
                                onChange={handleFileChange}
                            />
                            {imagePreviewUrl && (
                                <div className="mt-2">
                                    <img src={imagePreviewUrl} alt="Logo" className="w-24 h-auto" />
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-accent-70 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-70"
                        >
                            Register
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Register;
