import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from "@/components/ui/badge"
import { Cog6ToothIcon, BuildingStorefrontIcon, BuildingOfficeIcon, UsersIcon, ShoppingCartIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';
import './Sidebar.css';
import UserContext from '../../../contexts/UserContext';
import { useAdminStore } from '../../../contexts/AdminStoreContext';
import { Button } from '@/components/ui/button';
import { X } from 'lucide-react';

const Sidebar = ({enable, storeId, toggleDropdown, dropdownVisible}) => {
  const { token, user, organizationData } = useContext(UserContext);
  const { adminStore, isLoading } = useAdminStore();

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading indicator
  }

  return (
    <div className='sidebar border-r bg-muted/40 w-full md:max-w-60 lg:max-w-60 md:block lg:block'>
      <div className='flex flex-col gap-4'> 
        <div className='flex items-center px-6 justify-center'>
          <div className="flex items-center font-semibold justify-center gap-4">
            {adminStore ? (
              <>
                <Link to={`stores/${adminStore.id}`} className="flex items-center gap-4">
                  {adminStore.logoUrl ? (
                    <img 
                      src={adminStore.logoUrl} 
                      alt={adminStore.name} 
                      className="h-8 w-8 rounded-full"
                    />
                  ) : (
                    <BuildingStorefrontIcon className="h-8 w-8" />
                  )}
                  <h2 className="">{adminStore.name}</h2>
                </Link>
              </>
            ) : (
              <>
                <BuildingStorefrontIcon className="h-8 w-8" />
                <Link to={`stores`} className='text-muted-foreground text-sm font-light hover:text-primary'>
                  Please select a store...
                </Link>
              </>
            )}
          </div>
        </div>
        <div className='flex-1'>
          <Accordion type="single" collapsible className='grid items-start px-4 text-3 font-medium'>
            {adminStore && (
              <AccordionItem value="item-1">
                <AccordionTrigger className='flex items-center gap-3 rounded-lg bg-muted px-3 py-2 text-primary transition-all hover:text-primary'>
                  <BuildingStorefrontIcon className='h-4 w-4' />
                  <p>
                    My Store
                  </p>
                </AccordionTrigger>
                <AccordionContent className='pl-10 py-2'>
                  <ul>
                    <li><Link to={`stores/${storeId}/products`}>List/Edit Products</Link></li>
                    <li><Link to={`stores/${storeId}/share`}>Share Store</Link></li>
                    <li><Link to={`stores`}>All Stores</Link></li>
                  </ul>
                </AccordionContent>
              </AccordionItem>
            )}
            <AccordionItem value="item-2" className="flex justify-between items-center gap-3 rounded-lg px-3 py-2 text-primary transition-all hover:text-muted-foreground">              
                <ShoppingCartIcon className="h-4 w-4" />
                <Link to="orders">
                  Orders
                </Link>
                <Badge className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
                  {token && (
                    <span>0</span> 
                  )}
                </Badge>
            </AccordionItem>
            <AccordionItem value="item-3" className="flex justify-between items-center gap-3 rounded-lg px-3 py-2 text-primary transition-all hover:text-muted-foreground"> 
            <Cog6ToothIcon className="h-4 w-4" />
              <Link to="settings">
              Settings
              </Link>
              <div className='flex h-4 w-4'></div>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
