import React from 'react';
import './AddToCartButton.css';

const AddToCartButton = ({ text, onClick }) => {
  return (
    <div className='max-w-md mx-auto'>
      <button className="add-to-cart-button solid-button font-bold" onClick={onClick}>
        {text}
      </button>
    </div>  
  );
};

export default AddToCartButton;
