import React from 'react';
import { Switch } from "@/components/ui/switch"
import { TabsContent } from "@/components/ui/tabs"
import { Button } from "@/components/ui/button"

function EditProductSettings({ product, onProductChange }) {
    const handleSettingChange = (setting, checked) => {
        onProductChange({
            ...product,
            [setting]: checked
        });
    };

    return (
        <TabsContent value="settings">
            <div className="space-y-8">
                <div className="space-y-6">
                    {/* Advance Notice Section */}
                    <div className="border rounded-lg p-4 space-y-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <h3 className="text-lg font-semibold">Advance Notice</h3>
                                <p className="text-sm text-muted-foreground">Require approval before confirming bookings</p>
                            </div>
                            <Switch 
                                id="AdvanceNotice"
                                name="AdvanceNotice"
                                checked={product.AdvanceNotice}
                                onCheckedChange={(checked) => handleSettingChange('AdvanceNotice', checked)}
                                className="justify-start"
                            />
                        </div>
                    </div>

                    {/* Team Notifications Section */}
                    <div className="border rounded-lg p-4 space-y-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <h3 className="text-lg font-semibold">Team Notifications</h3>
                                <p className="text-sm text-muted-foreground">Notify team members of new bookings</p>
                            </div>
                            <Switch 
                                id="NotifyTeammates"
                                name="NotifyTeammates"
                                checked={product.NotifyTeammates}
                                onCheckedChange={(checked) => handleSettingChange('NotifyTeammates', checked)}
                                className="justify-start"
                            />
                        </div>
                    </div>

                    {/* Rental Option Section */}
                    <div className="border rounded-lg p-4 space-y-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <h3 className="text-lg font-semibold">Rental Item</h3>
                                <p className="text-sm text-muted-foreground">Mark this as a rental product</p>
                            </div>
                            <Switch 
                                id="IsRental"
                                name="IsRental"
                                checked={product.IsRental}
                                onCheckedChange={(checked) => handleSettingChange('IsRental', checked)}
                                className="justify-start"
                            />
                        </div>
                    </div>
                </div>
                <Button className="w-full bg-green-500 hover:bg-green-600">Save Changes</Button>
            </div>
        </TabsContent>
    )
}

export default EditProductSettings;