import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import { AdminStoreProvider } from '../contexts/AdminStoreContext';
import Navbar from '../components/admin/Navbar';
import Sidebar from '../components/admin/Sidebar';
import Login from '../screens/Login';
import WelcomeScreen from '../components/admin/WelcomeScreen'
import UserContext from '../contexts/UserContext';
import StoreList from '../components/admin/StoreList';
import AddStore from '../components/admin/AddStore';
import StoreDetails from '../components/admin/StoreDetails';
import ProductList from '../components/admin/ProductList';
import AddProduct from '../components/admin/AddProduct';
import OrdersList from '../components/admin/OrdersList';
import SelectProduct from '../components/admin/SelectProduct';
import ConfirmNewProduct from '../components/admin/ConfirmNewProduct';
import Onboarding from '../components/admin/Onboarding';
import ProductPreview from '../components/admin/ProductPreview';
import ShareStore from '../components/admin/ShareStore';
import Dashboard from '../components/admin/Dashboard';
import Settings from '../components/admin/Settings';
import { Toaster } from "@/components/ui/toaster"

function Admin() {
  const { token, user } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const [dropdownVisible, setDropdownVisible] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate('/admin/login');
    }
  }, [token, navigate]);

  useEffect(() => {
    if (location.state?.product) {
      setSelectedProduct(location.state.product);
    }
  }, [location]);

  const shouldEnableSubbar = () => {
    // Regular expression to match dynamic route /stores/:id
    const storeDetailRegex = /^\/admin\/stores\/\d+(\/.*)?$/;
    return storeDetailRegex.test(location.pathname);
  };

  const extractStoreId = () => {
    const match = location.pathname.match(/^\/admin\/stores\/(\d+)/);
    return match ? match[1] : null;
  };

  const storeId = extractStoreId();

  return (
      <AdminStoreProvider>
        <div className="App">
          <Navbar />
          <div className="main-layout overflow-hidden flex flex-row">
            {token && <Sidebar enable={shouldEnableSubbar()} storeId={storeId} token={token} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/> }
            <div className="main-content grow mx-auto">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="login" element={<Login />} />
                <Route path="/welcome" element={<WelcomeScreen />} />
                <Route path="/onboarding/*" element={<Onboarding />} />
                <Route path="/stores" element={<StoreList />} />
                <Route path="/stores/create" element={<AddStore />} />
                <Route path="/stores/:storeId" element={<StoreDetails />} />
                <Route path="/stores/:storeId/share" element={<ShareStore storeId={storeId} />} />
                <Route path="/stores/:storeId/products" element={<ProductList storeId={storeId} />} />
                <Route path="/stores/:storeId/products/create" element={<AddProduct storeId={storeId} />} />
                <Route path="/stores/:storeId/products/select" element={<SelectProduct />} />
                <Route path="/stores/:storeId/products/confirm-new-product" element={<ConfirmNewProduct product={selectedProduct} />} />
                <Route 
                  path="/stores/:storeId/products/:productId" 
                  element={
                    <ProductPreview 
                      product={selectedProduct}
                      onClose={() => {
                        setSelectedProduct(null);
                        navigate(`/admin/stores/${storeId}/products`);
                      }}
                    />
                  } 
                />
                <Route path="/orders" element={<OrdersList />} />
                <Route path="/settings" element={<Settings />} />
              </Routes>
            </div>
          </div>
          <Toaster />
        </div>
      </AdminStoreProvider>
  );
}

export default Admin;
