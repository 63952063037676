import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import Payment from './../Payment'

import sdk from './../../tools/sdk';

import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51O8x2LDavdWJkX9tAWds2QvKNoNClbFIc6U16TxFBFfWW3B6BWw3gneAF3vUyu1fhTt6OocXi7oaTPnFLnraq3cz00HTf2aXDM');
// Test key:
// const stripePromise = loadStripe('pk_test_51O8x2LDavdWJkX9tWlgFK9GdUTitrRibnxYSdfXNW0hdlm2EOgYsAepmz3Bcg17hlWyLd0H7b4uqY2dii1h4XXV900rfBkoOtT');

export default function CheckoutForm({customerId, storeId, storeSlug, cartTotal}) {
    const [clientSecret, setClientSecret] = useState('');
    const [paymentIntentId, setPaymentIntentId] = useState(null);

    useEffect(() => {
        let didCancel = false;

        const fetchClientSecret = async () => {
            try {
                if (!didCancel) {
                    // Remove dollar sign and convert to cents
                    const amountInCents = Math.round(parseFloat(cartTotal.replace('$', '')) * 100);
                    const response = await sdk.createPaymentIntent(amountInCents);
                    const data = await response.data;
                    setClientSecret(data.clientSecret);
                    setPaymentIntentId(data.id);
                }
            } catch (error) {
                if (!didCancel) {
                    console.error('Error fetching client secret:', error);
                }
            }
        };

        fetchClientSecret();

        return () => {
            didCancel = true;
        };
    }, [cartTotal]);

    return (
        <div>
        {clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <Payment customerId={customerId} storeId={storeId} storeSlug={storeSlug} paymentIntentId={paymentIntentId} />
          </Elements>
        )}
      </div>
    )
}
