import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import sdk from '../../../tools/sdk';
import CartContainer from '../CartContainer';

import CloseBar from '../CloseBar';
import RenderHTML from '../RenderHTML';
import FeeInfo from '../FeeInfo';
import AddToCartButton from '../AddToCartButton';
import ProductAttributes from '../ProductAttributes'; // Import the new component
import ShareButton from 'components/mobile/ShareButton';
import './ProductDetails.css';
import { StoreContext } from '../../../contexts/StoreContext';

function ProductDetails({ handleClose }) {
    const { storeSlug, id } = useParams();
    const productId = +id;
    const [product, setProduct] = useState({});
    const [store, setStore] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [errors, setErrors] = useState({});
    const [price, setPrice] = useState();

    const { addToCart } = useContext(StoreContext);

    const validateAttributes = () => {
        const newErrors = {};
        product.ProductAttributes.forEach(attribute => {
            if (attribute.IsRequired && !selectedAttributes[attribute.Id]) {
                newErrors[attribute.Id] = 'This field is required';
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAddToCartClick = (product) => {
        if (validateAttributes()) {
            const productWithAttributes = {
                ...product,
                selectedAttributes
            };
            addToCart(productWithAttributes, store.nop_store_id);
            handleClose();
        }
    };

    const handleAttributeChanged = (attributes) => {
        let total = 0;

        if (attributes) {
            total += Object.values(attributes).reduce((sum, attribute) => sum + attribute.priceAdjustmentValue, 0);
        }

        if (product) 
        total += product.Price;

        setPrice(total)
    }

    const init = async () => {
        const storeRes = await sdk.getStoreBySlug(storeSlug);
        setStore(storeRes.data);
        // TODO: fetch only one product
        const productsResponse = await sdk.getProductsForStore(storeRes.data.id);
        const productRes = productsResponse.data.find((p) => p.Id === productId);
        setProduct(productRes);
        setPrice(productRes.Price)
    };
    
    useEffect(() => {
        if (Object.keys(product).length > 0) {
            console.log('Product Details:', product);
        }
    }, [product]);

    useEffect(() => {
        init();
    }, []);

    return (
        <div className="product-details mb-8">
            <CartContainer>
                <CloseBar text={'Product Details'} handleClose={handleClose}></CloseBar>
                <div>
                    <div className="property-logo w-full md:max-w-md lg:max-w-md mx-auto">
                        <img src={product.ImageUrl} alt="Property Logo" />
                    </div>
                    <div className='product-header-box flex grid-cols-2'>
                        <div className="product-header grow mr-4">
                            <div className='product-name mb-1 text-5 font-bold'>
                                {product.Name}
                            </div>
                            <div className='product-short-description text-5 font-light'>
                                {product.ShortDescription}
                            </div>
                        </div>
                        <div className='place-self-center'>
                            <ShareButton />
                        </div>
                    </div>
                    <div className="product-info">
                        <div className="product-description text-4 font-light">
                            <RenderHTML htmlContent={product.FullDescription}></RenderHTML>
                        </div>
                    </div>
                    {product.ProductAttributes && product.ProductAttributes.length > 0 && (
                        <ProductAttributes
                            attributes={product.ProductAttributes}
                            selectedAttributes={selectedAttributes}
                            setSelectedAttributes={setSelectedAttributes}
                            handleAttributeChangedHandler={handleAttributeChanged}
                        />
                    )}
                    <div className='product-terms-header'>
                        <h2 className='text-4 font-medium'>Terms</h2>
                    </div>
                    <div className='product-terms-details'>
                        <p className='text-2 font-light'>Subject to schedule and vendor approval. All local laws and regulations apply.</p>
                    </div>
                    <hr className="divider" />
                    <div className='product-fee-details'>
                        <FeeInfo text={'Fee to Reserve'} fee={'$' + price} />
                        <AddToCartButton className='font-bold' text={'Add To Cart'} onClick={() => handleAddToCartClick(product)} />
                    </div>
                </div>
            </CartContainer>
        </div>
    );
}

export default ProductDetails;
