import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../../contexts/StoreContext';

function ProductCards({product}) {
    const navigate = useNavigate();

    return (
        <div key={product.Id} onClick={() => navigate(`products/${product.Id}`)} className="product-card pb-1 bg-accent-10 min-w-40 max-w-48 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md lg:aspect-none group-hover:opacity-75 shadow-md">
            <div className="min-w-full"><img src={product.ImageUrl} alt={product.Name} className="min-h-48 w-full object-cover object-center lg:h-full lg:w-full aspect-square" /></div>
            <div className="p-2 w-full">
                <h3 className="mt-1 font-normal text-skin-base text-nowrap">{product.Name}</h3>
                <p className="mt-1 text-4 text-skin-muted font-medium flex mx-auto">${product.Price}</p>
            </div>
            <div className="product-badge p-2 mx-auto">
                <button className='soft-button button-size-lg px-4' onClick={() => navigate(`products/${product.Id}`)}>{'See Details'}</button>
            </div>
        </div>
    )
}

export default ProductCards;