import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductList.css'; // Import the CSS for styling
import apiClient from '../../../tools/apiClient';
import { useAdminStore } from '../../../contexts/AdminStoreContext';

const ProductList = ({storeId}) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const { adminStore } = useAdminStore();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await apiClient.get(`/products/${storeId}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, [storeId]);

  const handleAddProductClick = () => {
    navigate(`/admin/stores/${storeId}/products/select`);
  };

  const handleAddNewProduct = () => {
    navigate(`/admin/stores/${storeId}/products/create`);
  };

  const handleViewProduct = (product) => {
    navigate(`/admin/stores/${storeId}/products/${product.Id}`, { state: { product } });
  };

  return (
    <div className="product-list">
      <div className="product-list-header">
        <div className="product-list-header-text flex flex-col">
          <h1>Product List:</h1>
          <h2 className="text-xl font-bold">{adminStore?.name || 'Loading...'}</h2>
        </div>
        <div className="button-group">
          <button className="add-product-button primary-button" onClick={handleAddProductClick}>+ Add product</button>
          <button className="add-product-button secondary-button" onClick={handleAddNewProduct}>+ New product</button>
        </div>
      </div>
      <div className="product-list-content">
        <div className="search-container">
          <input type="text" placeholder="Search products..." className="product-search" />
          <i className="search-icon">🔍</i>
        </div>
        <table className="product-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Product Name</th>
              <th>Description</th>
              <th>Price</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index}>
                <td>
                  <img src={product.ImageUrl} alt={product.name} className="product-image" />
                </td>
                <td>{product.Name}</td>
                <td>{product.Description}</td>
                <td>${product.Price.toFixed(2)}</td>
                <td>{product.Category}</td>
                <td>
                  <button className="action-button view m-2" onClick={() => handleViewProduct(product)}>View</button>
                  <button className="action-button edit m-2" onClick={() => navigate(`/admin/products/${product.Id}/edit`)}>Edit</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductList;
