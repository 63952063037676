import React, { useState, useEffect } from 'react';
import { StoreProvider } from '../../../contexts/StoreContext';
import ProductsDashboard from '../../mobile/ProductsDashboard';
import sdk from '../../../tools/sdk';
import apiClient from '../../../tools/apiClient';
import config from '../../../config';
import axios from 'axios';

function Store({ previewMode = false, onboardingData, onComplete }) {
  const [store, setStore] = useState(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [storeId, setStoreId] = useState(null);

  useEffect(() => {
    if (onboardingData && onboardingData.storeId) {
      setStoreId(onboardingData.storeId);
    }
  }, [onboardingData]);

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        let storeData;
        if (previewMode && storeId) {
          const response = await apiClient.get(`/stores/by-id/${storeId}`);
          storeData = response.data;
        } else {
          console.error('No store identifier provided');
          return;
        }

        setStore(storeData);

        // Use nop_store_id for subsequent API calls
        const nopStoreId = storeData.nop_store_id;
        console.log(nopStoreId);

        const categoriesResponse = await sdk.getCategories();
        const categoriesData = categoriesResponse.data;
        setCategories(categoriesData);
        setActiveCategory(categoriesData[0]);

        if (onboardingData.selectedProducts && onboardingData.selectedProducts.length > 0) {
          setProducts(onboardingData.selectedProducts);
          setFilteredProducts(onboardingData.selectedProducts);
        } else {
          const productsResponse = await sdk.getProductsForStore(nopStoreId);
          const productsData = productsResponse.data;
          setProducts(productsData);
          setFilteredProducts(productsData);
          console.log('Fetched products from API:', productsData);
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    };

    fetchStoreData();
  }, [storeId, previewMode, onboardingData]);

  useEffect(() => {
    if (activeCategory) {
      const filtered = products.filter(product => product.categoryId === activeCategory.id);
      setFilteredProducts(filtered);
    }
  }, [activeCategory, products]);

  const popularProducts = products.filter(product => product.Tags.includes(18));

  if (!store) {
    return <div>Loading...</div>;
  }

  const handleConfirm = async () => {
    try {
      for (const product of onboardingData.selectedProducts) {
        const productData = {
          Name: product.Name,
          Description: product.FullDescription || product.ShortDescription || "No description provided",
          CategoryId: product.Categories && product.Categories.length > 0 ? parseInt(product.Categories[0], 10) : 17,
          Price: parseFloat(product.Price) || 0,
          StoreId: storeId,
          AdvanceNotice: product.AdvanceNotice || false,
          NotifyTeammates: product.NotifyTeammates || false,
          IsRental: product.IsRental || false,
          File: product.File // This should now be the Blob object
        };

        console.log('Sending product data:', productData);

        try {
          const response = await axios.post(`${config.apiEndpoint}/products/create`, productData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response.status === 200 || response.status === 201) {
            console.log(`Product ${product.Name} created successfully`, response.data);
          } else {
            console.error(`Unexpected response status for product ${product.Name}:`, response.status);
          }
        } catch (error) {
          console.error(`Failed to create product ${product.Name}:`, error.response?.data || error.message);
        }
      }
      console.log('All products processed');
      onComplete();
    } catch (error) {
      console.error('Error in handleConfirm:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Store Preview</h1>
      <div className="border border-gray-300 rounded-lg overflow-hidden">
        <StoreProvider value={{ store }}>
          <ProductsDashboard
            store={store}
            categories={categories}
            popularProducts={popularProducts}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            filteredProducts={filteredProducts}
          />
        </StoreProvider>
      </div>
      <button
        className="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleConfirm}
      >
        Confirm
      </button>
    </div>
  );
}

export default Store;
