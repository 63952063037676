import React from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { X } from "lucide-react";
import apiClient from '../../../tools/apiClient';
import ProductCards from '../../../components/mobile/ProductCards';

const ProductPreview = ({ product, onClose }) => {
  if (!product) {
    return null;
  }

  const handleSave = async () => {
    try {
      await apiClient.put(`/products/${product.id}`, product);
      onClose();
    } catch (error) {
      console.error('Error saving product:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <Card className="w-full max-w-2xl bg-white">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>{product.name}</CardTitle>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </CardHeader>
        <CardContent>
          <ProductCards product={product} />
          <div className="flex justify-end space-x-4 mt-4">
            <Button variant="outline" onClick={onClose}>Cancel</Button>
            <Button onClick={handleSave}>Save Changes</Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ProductPreview;