import React from 'react';
import { CheckCircle2 } from "lucide-react"
import onboardingSteps from '../../../config/onboardingConfig';

const Breadcrumbs = ({ currentStep = 0 }) => {
  return (
    <nav className="mb-8 flex justify-center">
      <ol className="flex place-items-center space-x-2">
        {onboardingSteps.map((step, index) => (
          <li key={index} className="flex items-center">
            <div className={`flex items-center justify-center w-8 h-8 rounded-full ${
              index + 1 === currentStep ? 'bg-primary text-primary-foreground' : 'bg-muted text-muted-foreground'
            }`}>
              {index + 1 < currentStep ? <CheckCircle2 className="h-4 w-4" /> : index + 1}
            </div>
            <span className={`ml-2 text-sm ${
              index + 1 === currentStep ? 'font-medium' : 'text-muted-foreground'
            }`}>{step}</span>
            {index < onboardingSteps.length - 1 && <span className="mx-2 text-muted-foreground">/</span>}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;