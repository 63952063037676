import React from 'react';
import './CartButton.css';
import { useNavigate } from 'react-router-dom';
import { ShoppingCartIcon } from '@heroicons/react/24/outline'


const CartButton = ({count}) => {

  const navigate = useNavigate();

    return (
        <div className="cart-button pt-2 h-full" onClick={() => navigate(`cart`)}>
          <p className="mr-3 my-auto text-accent-100">({count})</p>
            <ShoppingCartIcon className='stroke-accent-100 h-8 w-8' />
        </div>
    );
};

export default CartButton;
