import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import RenderHTML from '../../mobile/RenderHTML';
import ShareButton from '../../mobile/ShareButton';
import { ArrowLeft, Clock, Pencil } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Textarea } from "@/components/ui/textarea"
import EditProductSettings from '../EditProductSettings';
import apiClient from '../../../tools/apiClient';
import axios from 'axios';
import config from 'config';

function ConfirmNewProduct() {
    const location = useLocation();
    const [product, setProduct] = useState({});
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchCategories = async () => {
          const response = await apiClient.get(`/categories`);
          setCategories(response.data)
      }
      fetchCategories()
  }, []);
  
    useEffect(() => {
        const fetchProductDetails = async () => {
            if (location.state && location.state.selectedProduct) {
                setProduct(location.state.selectedProduct);
                
                if (location.state.selectedProduct.Id) {
                    try {
                        const response = await fetch(`https://ncommerce.dev.greeter.store/api/product/${location.state.selectedProduct.Id}/image`);
                        if (response.ok) {
                            const fileData = await response.text();
                            setProduct(prevProduct => ({
                                ...prevProduct,
                                File: fileData,
                                ImageUrl: location.state.selectedProduct.ImageUrl || null
                            }));
                        }
                    } catch (error) {
                        console.error('Error fetching product image:', error);
                    }
                }
            }
        };
        
        fetchProductDetails();
    }, [location.state]);
  
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct(prevProduct => {
            const updatedProduct = {
                ...prevProduct,
                [name]: value
            };
            console.log('Updated Product:', updatedProduct);
            return updatedProduct;
        });
    };

    const saveProduct = async (e) => {
        e.preventDefault();

        let storeId = location.state.storeId;

        if (!storeId) {
            const urlMatch = location.pathname.match(/\/admin\/stores\/(\d+)/);
            if (urlMatch && urlMatch[1]) {
                storeId = parseInt(urlMatch[1], 10);
            } else {
                console.error('No store ID found in URL or context');
                return;
            }
        }

        try {
            const productData = {
                Name: product.Name,
                Description: product.ShortDescription,
                CategoryId: parseInt(product.CategoryId, 10),
                Price: parseFloat(product.Price),
                AdvanceNotice: product.AdvanceNotice || false,
                NotifyTeammates: product.NotifyTeammates || false,
                IsRental: product.IsRental || false,
                StoreId: storeId,
                File: product.File
            };

            const response = await axios.post(`${config.apiEndpoint}/products/create`, productData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 || response.status === 201) {
                console.log('Product created successfully');
                navigate(`/admin/stores/${storeId}/`);
            }
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    return (
          <div className="min-h-screen bg-gray-100 p-4 md:p-8">
            <Card className="mx-auto max-w-4xl bg-white">
              <CardHeader className="flex flex-row items-center justify-between">
                <div className="flex items-center space-x-4">
                  <Button 
                    variant="ghost" 
                    size="icon"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </Button>
                  <CardTitle className="text-2xl font-bold">Confirm New Product</CardTitle>
                </div>
              </CardHeader>
              <CardContent>
                <Tabs defaultValue="description" className="w-full">
                  <TabsList className="grid w-full grid-cols-2 mb-6 min-h-12">
                    <TabsTrigger value="description">Description</TabsTrigger>
                    <TabsTrigger value="settings">Settings</TabsTrigger>
                  </TabsList>
                  <TabsContent value="description">
                    <div className="space-y-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <h3 className="text-lg font-semibold">Edit Photo</h3>
                          <p className="text-sm text-muted-foreground">Upload or take new photo</p>
                        </div>
                        <Button variant="outline" size="icon">
                          <Pencil className="h-4 w-4" />
                        </Button>
                      </div>
                      <div className="flex items-center justify-center h-40 bg-muted rounded-lg aspect-square mx-auto">
                        {product.ImageUrl ? (
                          <img 
                            src={product.ImageUrl} 
                            alt={product.Name} 
                            className="h-full w-full object-cover rounded-lg"
                          />
                        ) : (
                          <Clock className="h-12 w-12 text-muted-foreground" />
                        )}
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="title" className="text-md font-semibold">Title</Label>
                        <Input id="title" name="Name" value={product.Name || ''} onChange={handleInputChange} />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="subtitle" className="text-md font-semibold">Subtitle</Label>
                        <Input id="subtitle" name="ShortDescription" value={product.ShortDescription || ''} onChange={handleInputChange} />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="description" className="text-md font-semibold">Description</Label>
                        <Textarea
                          id="description"
                          name="FullDescription"
                          value={product.FullDescription || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                      {/* Category selection - full width */}
                      <div className="md:col-span-2 space-y-2">
                        <Label htmlFor="category" className="text-md font-semibold">Category</Label>
                        <select
                          id="CategoryId"
                          name="CategoryId"
                          value={product.CategoryId || ''}
                          onChange={handleInputChange}
                          className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                        >
                          <option value="">Select Category</option>
                          {categories.map(category => (
                            <option key={category.Id} value={category.Id}>
                              {category.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                      <div className="space-y-2">
                        <Label htmlFor="fee" className="text-md font-semibold">Fee</Label>
                        <div className="relative">
                          <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
                          <Input 
                            id="fee" 
                            type="number" 
                            name="Price" 
                            value={product.Price || ''} 
                            onChange={handleInputChange}
                            className="pl-6"
                          />
                        </div>
                      </div>
                      <div className="text-sm text-muted-foreground">You make: ${(product.Price * 0.93).toFixed(2)}</div>
                      <Button onClick={saveProduct} className="w-full bg-green-500 hover:bg-green-600">
                          Save Changes
                      </Button>
                    </div>
                  </TabsContent>
                  <TabsContent value="settings">
                    <EditProductSettings 
                        product={product}
                        onProductChange={setProduct}
                    />
                  </TabsContent>
                </Tabs>
              </CardContent>
            </Card>
          </div>
        )
  }

export default ConfirmNewProduct; 