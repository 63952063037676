import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './StoreList.css'; // Import the CSS for styling
import apiClient from '../../../tools/apiClient';
import { useAdminStore } from '../../../contexts/AdminStoreContext';

const StoreList = () => {
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);
  const { setStoreId } = useAdminStore();

  useEffect(() => {
    const fetchStores = async () => {
      const response = await apiClient.get(`/stores`);
      setStores(response.data);
    };
    fetchStores();
  }, []);

  const handleAddStoreClick = () => {
    navigate('/admin/stores/create');
  };

  const handleStoreClick = (storeId) => {
    setStoreId(storeId);
    console.log(storeId);
    navigate(`/admin/stores/${storeId}`);
  };

  return (
    <div className="store-list">
      <div className="store-list-header">
        <h1>Stores</h1>
        <button className="add-store-button outline-button text-skin-accent"  onClick={handleAddStoreClick}>+ Add store</button>
      </div>
      <div className="store-list-content">
        <input type="text" placeholder="Search..." className="store-search" />
        <table className="store-table">
          <thead>
            <tr>
              <th>Store Name</th>
              <th>Store Link</th>
              <th>Zip Code</th>
              <th>Products</th>
              <th>Orders</th>
              <th>Sales</th>
            </tr>
          </thead>
          <tbody>
            {stores.map((store, index) => (
              <tr key={index}>
                <td className="store-name">
                  <img src={store.logoUrl} alt="Store Logo" className="store-logo" />
                  <Link to={`/admin/stores/${store.id}`} onClick={() => handleStoreClick(store.id)}>
                    {store.name}
                  </Link>
                </td>
                <td><a target="_blank" rel="noopener noreferrer" href={`/${store.slug}`}>{store.slug}</a></td>
                <td>{store.zipCode}</td>
                <td>{store.products}</td>
                <td>{store.orders}</td>
                <td>{store.sales}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StoreList;
