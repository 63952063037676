import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAdminStore } from '../../../contexts/AdminStoreContext';
import { StoreProvider } from '../../../contexts/StoreContext';
import ProductsDashboard from '../../mobile/ProductsDashboard';
import apiClient from '../../../tools/apiClient';

const StoreDetails = () => { 
    const { storeId } = useParams();
    const { adminStore } = useAdminStore();
    const [store, setStore] = useState(null);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);

    useEffect(() => {
        const fetchStoreData = async () => {
            try {
                const storeResponse = await apiClient.get(`/stores/by-id/${storeId}`);
                setStore(storeResponse.data);

                const categoriesResponse = await apiClient.get('/categories');
                setCategories(categoriesResponse.data);
                setActiveCategory(categoriesResponse.data[0]);

                const productsResponse = await apiClient.get(`/products/${storeId}`);
                setProducts(productsResponse.data);
            } catch (error) {
                console.error('Error fetching store data:', error);
            }
        };

        fetchStoreData();
    }, [storeId]);

    if (!store) {
        return <div>Loading...</div>;
    }

    const popularProducts = products.filter(product => product.Tags.includes(18));
    const filteredProducts = activeCategory
        ? products.filter(product => product.Categories.includes(activeCategory.Id))
        : products;

    return (
        <div className="container mx-auto px-4 py-8">
            <div className='flex justify-between items-center pb-8'>
                <h1 className="text-3xl font-bold">Store Preview</h1>
                <div className='flex gap-4 justify-end'>
                    <Link 
                        to={`/admin/stores/${storeId}/share`}
                        className="bg-blue-300 hover:bg-blue-400 text-white primary-button py-2 px-4 rounded"
                    >
                        Share Store
                    </Link>
                    <Link 
                        to={`/admin/stores/${storeId}/products`}
                        className="bg-blue-300 hover:bg-blue-400 text-white secondary-button py-2 px-4 rounded"
                    >
                        List Products
                    </Link>
                </div>
            </div>
            <div className="store-preview-container border border-gray-300 rounded-lg overflow-hidden">
                <StoreProvider value={{ store }}>
                    <ProductsDashboard
                        store={store}
                        categories={categories}
                        popularProducts={popularProducts}
                        activeCategory={activeCategory}
                        setActiveCategory={setActiveCategory}
                        filteredProducts={filteredProducts}
                    />
                </StoreProvider>
            </div>
        </div>
    );
}

export default StoreDetails;
