import React, { useEffect, useState } from 'react';
import './ProductAttributes.css';

function ProductAttributes({ attributes, selectedAttributes, setSelectedAttributes, handleAttributeChangedHandler }) {
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Set default selected values for attributes with default selections
        attributes.forEach(attribute => {
            const defaultValue = attribute.Values.find(value => value.IsPreSelected);
            if (defaultValue) {
                setSelectedAttributes(prevSelected => ({
                    ...prevSelected,
                    [attribute.Id]: defaultValue.Id
                }));
                handleAttributeChangedHandler({
                    ...selectedAttributes,
                    [attribute.Id]: {id: defaultValue.Id, priceAdjustmentValue: defaultValue.PriceAdjustmentValue}
                });
            }
        });
    }, [attributes, setSelectedAttributes]);

    const handleAttributeChange = (attributeId, valueId, isRequired) => {
        const attribute = attributes.find(x => x.Id === attributeId);
        const value = attribute.Values.find(v => v.Id === valueId);
        
        setSelectedAttributes(prevSelected => ({
            ...prevSelected,
            [attributeId]: valueId
        }));

        handleAttributeChangedHandler({
            ...selectedAttributes,
            [attributeId]: {id: valueId, priceAdjustmentValue: value.PriceAdjustmentValue}
        });

        // Add or remove error only if the attribute is required
        if (isRequired) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [attributeId]: valueId === '' ? 'This field is required' : ''
            }));
        }
    };

    return (
        <div className="product-attributes px-4">
            {attributes.map(attribute => (
                <div key={attribute.Id} className="product-attribute flex grid-cols-2">
                    <label className="attribute-label flext font-medium text-skin-muted text-lg align-middle w-full">
                        {attribute.Name} {attribute.IsRequired && <span className="required">*</span>}
                    </label>
                    <div className='attribute-select'>
                        <select
                            className="w-24 float-right px-4 rounded border-accent-80 text-accent-100"
                            value={selectedAttributes[attribute.Id] || ''}
                            onChange={(e) => handleAttributeChange(attribute.Id, Number(e.target.value), attribute.IsRequired)}
                        >
                            <option value="">Please select</option>
                            {attribute.Values.map(value => (
                                <option key={value.Id} value={value.Id}>
                                    {value.Name} {value.PriceAdjustment ? `(${value.PriceAdjustment})` : ''}
                                </option>
                            ))}
                        </select>
                        {errors[attribute.Id] && <div className="error">{errors[attribute.Id]}</div>}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ProductAttributes;
