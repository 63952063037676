import React, { createContext, useState, useEffect, useContext } from 'react';
import apiClient from '../tools/apiClient';

const AdminStoreContext = createContext();

export const AdminStoreProvider = ({ children }) => {
  const [adminStore, setAdminStore] = useState(null);
  const [storeId, setStoreId] = useState(null);

  useEffect(() => {
    const fetchAdminStore = async () => {
      if (storeId) {
        try {
          const response = await apiClient.get(`/stores/by-id/${storeId}`);
          setAdminStore(response.data);
        } catch (error) {
          console.error('Error fetching admin store:', error);
          setAdminStore(null);
        }
      } else {
        setAdminStore(null);
      }
    };

    fetchAdminStore();
  }, [storeId]);

  return (
    <AdminStoreContext.Provider value={{ adminStore, setStoreId }}>
      {children}
    </AdminStoreContext.Provider>
  );
};

export const useAdminStore = () => useContext(AdminStoreContext);

export default AdminStoreContext;
