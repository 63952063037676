import React from 'react';
import './CartContainer.css';

const CartContainer = ({ children }) => {
  return (
    <div className="cart-container max-w-screen-lg mx-auto">
      <div className="cart-container-inner">
        {children}
      </div>
    </div>
  );
};

export default CartContainer;