import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CloseBar from '../CloseBar';
import AddToCartButton from '../AddToCartButton'
import './Acceptance.css';
import sdk from 'tools/sdk';
import CartContainer from '../CartContainer';

const Acceptance = ({ handleClose }) => {
    const [transactionDetails, setTransactionDetails] = useState(null);
    const location = useLocation();
    const { orderId } = useParams();

    const init = async () => {
        const responseOrderDetails = await sdk.getOrderDetails(orderId);
        const paymentIntentId = responseOrderDetails.data.payment_intent_id;
        const orderID = responseOrderDetails.data.nop_order_id

        const status = 'complete';
        const response = await sdk.updateOrderPaidStatus(paymentIntentId, orderID, {
            status: status
        })

        const date = new Date().toISOString(); // You would get this from your backend

        
        setTransactionDetails({
            amount: response.data.OrderTotal,
            date: date,
        });
    }

    useEffect(() => {
        init()
    }, [location]);



    if (!transactionDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="payment-details mb-8">
            <CartContainer>
                <CloseBar text={'Transaction Details'} handleClose={handleClose}></CloseBar>
                <div className="content mx-4">
                    <div className="completion-message">
                        <h1>Order Accepted!</h1>
                        <p>A confirmation email has been sent to the customer.</p>
                    </div>
                    <div className="transaction-details">
                        <h2>Transaction Details</h2>
                        <p><strong>Transaction ID:</strong> {transactionDetails.id}</p>
                        <p><strong>Amount:</strong> ${(transactionDetails.amount).toFixed(2)}</p>
                        <p><strong>Date:</strong> {new Date(transactionDetails.date).toLocaleDateString()}</p>
                    </div>
                    <div className='product-fee-details'>
                            <AddToCartButton text={'Got It!'} onClick={handleClose} />

                    </div>
                </div>
            </CartContainer>
        </div>

    );
};

export default Acceptance;
