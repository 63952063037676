import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './Login.css';
import axios from 'axios';
import config from 'config';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';

function Login() {
    const { storeSlug } = useParams();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(UserContext);

    const validateForm = () => {
        let newErrors = {};
        // Email validation
        if (!email || !email.includes('@')) {
            newErrors.email = 'Please enter a valid email address.';
        }
        // Password validation
        if (!password || password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters long.';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setErrors({});

        try {
            const response = await axios.post(`${config.apiEndpoint}/login`, { email, password });
            const { token } = response.data;
            login(token);
            navigate('/admin');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setApiError('Invalid email or password. Please try again.');
            } else {
                setApiError('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        alt="SuperGreeter"
                        src="https://supergreeter-product-resources.s3.amazonaws.com/companyName/email-resources/SuperGreeter-logo-overhang.png"
                        className="mx-auto max-h-24"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {apiError && (
                            <div className="text-red-500 text-sm">
                                {apiError}
                            </div>
                        )}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-80 sm:text-sm sm:leading-6"
                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && (
                                    <div className="text-red-500 text-sm">
                                        {errors.email}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="text-sm">
                                    <a href="#" className="font-semibold text-accent-80 hover:text-accent-70">
                                        Forgot password?
                                    </a>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-60 sm:text-sm sm:leading-6"
                                    value={password} onChange={(e) => setPassword(e.target.value)}
                                />
                                {errors.password && (
                                    <div className="text-red-500 text-sm">
                                        {errors.password}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-accent-80 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-60"
                            >
                                Sign in
                            </button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member?{' '}
                        <a href="/register" className="font-semibold leading-6 text-accent-80 hover:text-accent-70">
                            Start your free store!
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
}

export default Login;
