import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "../../../@/components/ui/card";
import { Button } from "../../../@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../@/components/ui/table"
import { Badge } from "../../../@/components/ui/badge"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { DollarSign, ShoppingBag, Store, TrendingUp } from 'lucide-react'
import UserContext from '../../../contexts/UserContext';
import apiClient from '../../../tools/apiClient';

const MetricCard = ({ title, value, icon }) => (
  <Card>
    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
      <CardTitle className="text-sm font-medium">{title}</CardTitle>
      {icon}
    </CardHeader>
    <CardContent>
      <div className="text-2xl font-bold">{value}</div>
    </CardContent>
  </Card>
)

const recentOrders = [
  { id: 1, customer: 'John Doe', product: 'Massage Therapy 1 Hr', date: '2024-09-26', status: 'Completed' },
  { id: 2, customer: 'Jane Smith', product: 'Airport Shuttle Service', date: '2024-09-25', status: 'Pending' },
  { id: 3, customer: 'Bob Johnson', product: 'Fresh Flower Delivery', date: '2024-09-24', status: 'Completed' },
  { id: 4, customer: 'Alice Brown', product: 'Late Check-Out', date: '2024-09-23', status: 'Cancelled' },
]

const topProducts = [
  { name: 'Massage Therapy 1 Hr', sales: 42 },
  { name: 'Airport Shuttle Service', sales: 38 },
  { name: 'Fresh Flower Delivery', sales: 30 },
  { name: 'Late Check-Out', sales: 25 },
  { name: 'Early Check-In', sales: 20 },
]


function Dashboard() {
  const [stores, setStores] = useState([]);
  const navigate = useNavigate();
  const { organizationData } = useContext(UserContext);

  useEffect(() => {
    const fetchStores = async () => {
      const response = await apiClient.get(`/stores`);
      setStores(response.data);
    };
    fetchStores();
    
    console.log('Organization data in Dashboard:', organizationData);
  }, [organizationData]);

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
      
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4 mb-8">
        <MetricCard title="Total Revenue" value="$12,426" icon={<DollarSign className="h-4 w-4 text-muted-foreground" />} />
        <MetricCard title="Total Orders" value="324" icon={<ShoppingBag className="h-4 w-4 text-muted-foreground" />} />
        <Link to="stores">
          <MetricCard 
            title="Active Stores" 
            value={stores?.length || 0} 
            icon={<Store className="h-4 w-4 text-muted-foreground" />} 
          />
        </Link>
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
            <Badge className="text-sm font-light py-2 px-4" variant="secondary">Coming Soon</Badge>
          </div>
          <MetricCard title="Conversion Rate" value="12.5%" icon={<TrendingUp className="h-4 w-4 text-muted-foreground" />} />
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2 mb-8">
        <Card className="relative overflow-hidden">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
            <Badge className="text-sm font-light py-2 px-4" variant="secondary">Coming Soon</Badge>
          </div>
          <CardHeader>
            <CardTitle>Recent Orders</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Order ID</TableHead>
                  <TableHead>Customer</TableHead>
                  <TableHead>Product</TableHead>
                  <TableHead>Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {recentOrders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{order.customer}</TableCell>
                    <TableCell>{order.product}</TableCell>
                    <TableCell>
                      <Badge variant={order.status === 'Completed' ? 'success' : order.status === 'Pending' ? 'warning' : 'destructive'}>
                        {order.status}
                      </Badge>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Top Selling Products</CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={topProducts}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="sales" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </div>

      <Card className="relative overflow-hidden">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
          <Badge className="text-sm font-light py-2 px-4" variant="secondary">Coming Soon</Badge>
        </div>
        <CardHeader>
          <CardTitle>Inventory Status</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Product</TableHead>
                <TableHead>In Stock</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>Massage Therapy 1 Hr</TableCell>
                <TableCell>15 slots</TableCell>
                <TableCell><Badge variant="success">Available</Badge></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Airport Shuttle Service</TableCell>
                <TableCell>8 vehicles</TableCell>
                <TableCell><Badge variant="warning">Limited</Badge></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fresh Flower Delivery</TableCell>
                <TableCell>50 bouquets</TableCell>
                <TableCell><Badge variant="success">Available</Badge></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Baby Equipment Rental</TableCell>
                <TableCell>2 cribs</TableCell>
                <TableCell><Badge variant="destructive">Low Stock</Badge></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );    
}

export default Dashboard;
