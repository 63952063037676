import React, { useState, useEffect } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { CheckCircle2, Copy, Facebook, Twitter, Linkedin, Clock, Check } from "lucide-react"
import { Toast } from "@/components/ui/toast"
import { useToast } from "@/hooks/use-toast"
import apiClient from "../../../tools/apiClient"

function ShareStore({ storeId }) {
  const [showToast, setShowToast] = useState(false)
  const [activeTab, setActiveTab] = useState("templates")
  const [shareStoreData, setShareStoreData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { toast } = useToast()

  useEffect(() => {
    async function fetchStoreData() {
      if (!storeId) return
      
      try {
        setIsLoading(true)
        const response = await apiClient.get(`/stores/by-id/${storeId}`)
        setShareStoreData(response.data)
        console.log('Store Data:', response.data)
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to load store data",
          variant: "destructive",
        })
        console.error('Failed to fetch store data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchStoreData()
  }, [storeId])

  const storeUrl = shareStoreData ? `https://greeter.store/${shareStoreData.slug}` : "https://greeter.store/";

  const templates = [
    {
      title: "At booking",
      timing: "Sent immediately after booking",
      content: `Thanks for booking! Check-in details and local recommendations: **${storeUrl}**. Questions? Let us know!`
    },
    {
      title: "One week before check-in",
      timing: "Sent 7 days before arrival",
      content: `Your trip is coming up! Need early check-in or local services? Book here: **${storeUrl}**. We'll send check-in instructions soon.`
    },
    {
      title: "At check-in",
      timing: "Sent 30 minutes after check-in",
      content: `We hope you're enjoying your stay! For available upgrades and local recommendations, check out our store link: **${storeUrl}**`
    }
  ]
  const platforms = ['Airbnb', 'Booking.com', 'VRBO', 'Expedia']
  const onCopy = () => {}

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text)
      setShowToast(true)
      setTimeout(() => setShowToast(false), 3000)
      onCopy?.(text)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  const getTemplateContent = (template) => {
    const content = template.content.replace('5641', storeId)
    return content.replace(/\*\*(.*?)\*\*/g, (_, url) => (
      `<a href="${url}" target="_blank" rel="noopener noreferrer" class="font-bold text-primary hover:underline">${url}</a>`
    ))
  }

  return (
    <Card className="max-w-3xl mx-auto p-6 mt-10">
      {isLoading ? (
        <div className="flex items-center justify-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      ) : (
        <>
          <CardHeader>
            <h1 className="text-3xl font-bold mb-6 text-center">Share your Store</h1>
          </CardHeader>
          <CardContent>
            <Tabs defaultValue="link" className="w-full">
              <TabsList className="grid w-full grid-cols-2 min-h-fit">
                <TabsTrigger value="link" isActive={activeTab === 'link'}
                    onClick={setActiveTab}>Store Link</TabsTrigger>
                <TabsTrigger value="templates" isActive={activeTab === 'templates'}
                    onClick={setActiveTab}>Messaging Templates</TabsTrigger>
              </TabsList>
              <TabsContent value="link">
                <Card>
                  <CardHeader>
                    <CardTitle>Share with Guests</CardTitle>
                    <CardDescription>Share your store link directly with your guests.</CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="flex space-x-2">
                      <Input value={storeUrl} readOnly />
                      <Button variant="outline" onClick={() => {
                        navigator.clipboard.writeText(storeUrl);
                        toast({
                          title: "Copied!",
                          description: "Store link copied to clipboard",
                        });
                      }}>
                        <Copy className="w-4 h-4 mr-2" />
                        Copy
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
              <TabsContent value="templates" activeValue={activeTab}>
                <div className="space-y-6">
                  {templates.map((template, index) => (
                    <Card key={index} className="shadow-md hover:shadow-lg transition-shadow duration-300">
                      <CardHeader className="bg-primary/10">
                        <div className="flex items-center justify-between">
                          <CardTitle className="text-xl font-bold text-primary">
                            {template.title}
                          </CardTitle>
                          <div className="flex items-center text-sm text-muted-foreground">
                            <Clock className="w-4 h-4 mr-1" />
                            <span>{template.timing}</span>
                          </div>
                        </div>
                      </CardHeader>
                      <CardContent className="pt-4">
                        <p className="text-md mb-4" dangerouslySetInnerHTML={{ __html: getTemplateContent(template) }} />
                        <Button className="w-full" variant="outline" onClick={() => {
                        navigator.clipboard.writeText(getTemplateContent(template));
                        toast({
                            title: "Copied!",
                            description: "Message copied to clipboard",
                          });
                        }}> 
                          <Copy className="w-4 h-4 mr-2" />
                          Copy message
                        </Button>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </TabsContent>
            </Tabs>
            {/* <div className="mt-8">
                <h3 className="text-lg font-semibold mb-4">Share your messaging on:</h3>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                  {platforms.map((platform) => (
                    <div 
                      key={platform} 
                      className="flex items-center justify-center bg-secondary/10 rounded-lg p-4 shadow-sm"
                    >
                      <span className="text-sm">{platform}</span>
                    </div>
                  ))}
              </div>
            </div> */}
          </CardContent>
          {showToast && (
            <Toast>
              <Check className="icon" />
              Copied to clipboard!
            </Toast>
          )}
        </>
      )}
    </Card>
  )
}

export default ShareStore;