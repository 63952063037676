import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs';
import AddStore from '../AddStore';
import OnboardProducts from '../OnboardProducts';
import StoreOnboarding from '../StoreOnboarding';
import ShareStore from '../ShareStore';
import onboardingSteps from '../../../config/onboardingConfig';

function Onboarding() {
  const [currentStep, setCurrentStep] = useState(0);
  const [onboardingData, setOnboardingData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const savedData = localStorage.getItem('onboardingData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setOnboardingData(parsedData);
    }

    if (location.pathname === '/admin/onboarding') {
      navigate('add-store');
    }

    const currentPath = location.pathname.split('/').pop();
    const stepIndex = onboardingSteps.findIndex(step => 
      step.toLowerCase().replace(/\s+/g, '-') === currentPath
    );
    if (stepIndex !== -1) {
      setCurrentStep(stepIndex);
    }
  }, [navigate, location]);

  const handleNextStep = async (data) => {
    const updatedData = { ...onboardingData, ...data };

    if (data && data.selectedProducts && Array.isArray(data.selectedProducts)) {
      try {
        updatedData.selectedProducts = await Promise.all(data.selectedProducts.map(async product => {
          let fileData = null;
          if (product && product.Id) {
            try {
              const response = await fetch(`https://ncommerce.dev.greeter.store/api/product/${product.Id}/image`);
              if (response.ok) {
                fileData = await response.text();
              }
            } catch (error) {
              console.error(`Error fetching image for product ${product.Id}:`, error);
            }
          }

          return {
            ...product,
            Categories: product.Categories && Array.isArray(product.Categories) && product.Categories.length > 0 
              ? product.Categories 
              : [17],
            File: fileData || (product.ImageUrl ? new Blob([product.ImageUrl], { type: 'text/plain' }) : null),
          };
        }));

        const allCategoryIds = new Set();
        updatedData.selectedProducts.forEach(product => {
          if (product && Array.isArray(product.Categories)) {
            product.Categories.forEach(categoryId => allCategoryIds.add(categoryId));
          }
        });
        updatedData.productCategories = Array.from(allCategoryIds);
      } catch (error) {
        console.error('Error processing selected products:', error);
      }
    }

    setOnboardingData(updatedData);
    localStorage.setItem('onboardingData', JSON.stringify(updatedData));

    console.log('Updated onboarding data:', updatedData);

    if (currentStep < onboardingSteps.length - 1) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      const nextPath = onboardingSteps[nextStep].toLowerCase().replace(/\s+/g, '-');
      navigate(nextPath);
    } else {
      localStorage.removeItem('onboardingData');
      navigate('/admin/dashboard');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="w-full flex justify-center">
        <Breadcrumbs steps={onboardingSteps} currentStep={currentStep + 1} />
      </div>
      <Routes>
        <Route index element={<Navigate to="add-store" replace />} />
        <Route path="add-store" element={
          <AddStore onComplete={(storeData) => handleNextStep({ storeId: storeData.storeId })} />
        } />
        <Route path="customize-your-store" element={
          <OnboardProducts onComplete={handleNextStep} storeData={onboardingData} />
        } />
        <Route path="preview-your-store" element={
          <StoreOnboarding 
            previewMode={true} 
            storeId={onboardingData.storeId} 
            onboardingData={onboardingData}
            onComplete={handleNextStep} 
          />
        } />
        <Route path="automate-sharing" element={
          <ShareStore onComplete={handleNextStep} storeId={onboardingData.storeId} />
        } />
      </Routes>
    </div>
  );
}

export default Onboarding;
