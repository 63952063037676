import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuItem} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button"
import './Navbar.css';
import Sidebar from '../Sidebar';
import UserContext from '../../../contexts/UserContext';
import { X } from "lucide-react";

const Navbar = () => {
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { token, user, organizationData, logout } = useContext(UserContext);
  const navigate = useNavigate();

  const toggleUserMenu = () => {
    setUserMenuVisible(!userMenuVisible);
  };

  const handleLogout = () => {
    logout();
    setUserMenuVisible(false)
    navigate('/admin/login');
  };

  const handleCloseSidebar = () => {
    const sidebarElement = document.querySelector('.sidebar-mobile-container');
    sidebarElement.classList.add('closing');
    
    setTimeout(() => {
      setIsSidebarOpen(false);
    }, 300);
  };

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true);
    setTimeout(() => {
      const sidebarElement = document.querySelector('.sidebar-mobile-container');
      sidebarElement.classList.add('open');
    }, 10);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-left flex items-center">
          <button 
            className="lg:hidden md:hidden mr-4 p-2 hover:bg-muted rounded-md"
            onClick={handleOpenSidebar}
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
          <div className="navbar-brand mx-3">
            <div className='flex flex-row items-center'>
              <Link to="/admin/">
                <img src="https://supergreeter-product-resources.s3.amazonaws.com/companyName/email-resources/SuperGreeter-hands-sml.png" class="h-8" alt="SuperGreeter Hands Logo" />
              </Link>
              <span>{organizationData?.name}</span>
            </div>
          </div>
        </div>
        {token && user && (
          <div className="navbar-user ml-auto content-center">
            <div onClick={toggleUserMenu} className="user-menu-toggle flex items-center">
              <div>
                {user.user.email}
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button className="mx-3 bg-white rounded-full p-0 w-12 h-12 overflow-hidden">
                    {organizationData && organizationData.logoUrl ? (
                      <img
                        src={organizationData.logoUrl}
                        className="rounded-full w-10/12 h-10/12 object-cover"
                        alt={organizationData.name}
                      />
                    ) : (
                      <img
                        src="/placeholder.svg"
                        width="32"
                        height="32"
                        className="rounded-full"
                        alt="Avatar"
                        style={{ aspectRatio: "32/32", objectFit: "cover" }}
                      />
                    )}
                    <span className="sr-only">Toggle user menu</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className='bg-white'>
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Support</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        )}
      </nav>

      {/* Mobile sidebar overlay */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-50 lg:hidden md:hidden"
          onClick={handleCloseSidebar}
        >
          <div 
            className="sidebar-mobile-container fixed inset-y-0 left-0 w-64 bg-white shadow-lg"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-end p-4 bg-muted">
              <Button variant="ghost" size="icon" onClick={handleCloseSidebar}>
                <X className="h-6 w-6" />
              </Button>
            </div>
            <div className="h-full overflow-y-auto">
              <Sidebar />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
