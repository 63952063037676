import React, { useState } from 'react';
import './CloseBar.css';
import xIcon from '../../../assets/mobile/x.png';

function CloseBar({text, handleClose}) {
  const [isVisible, setIsVisible] = useState(true);


  return (
    <div className="close-bar bg-accent-20 rounded-b p-4 shadow mb-8">
      <button className="close-button" onClick={handleClose}>
        <img src={xIcon} alt="Close" className="close-icon" />
      </button>
      <span className="close-bar-text text-5 font-bold text-skin-muted">{text}</span>
    </div>
  );
};

export default CloseBar;