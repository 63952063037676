import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/Header'
import Welcome from '../components/Welcome'
import ShoppingCart from '../components/ShoppingCart'
import CheckoutForm from '../components/CheckoutForm'
import Products from '../components/Products'

import ProductsDashboard from '../components/mobile/ProductsDashboard'
import ProductDetails from '../components/mobile/ProductDetails'
import CartDetails from '../components/mobile/CartDetails'
import Payments from '../components/mobile/Payments'
import Completion from '../components/mobile/Completion'
import Acceptance from '../components/mobile/Acceptance'
import CartContainer from '../components/mobile/CartContainer';

import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import sdk from '../tools/sdk';
import { StoreContext } from '../contexts/StoreContext';

import('./../styles/Store.css');

const Notification = ({ message, type, onClose }) => {
  if (!message) return null;

  return (
    <div className={`notification-panel ${type}`}>
    <div className="notification-panel-content">
      <p>{message}</p>
      <button onClick={onClose} aria-label="Close Notification">X</button>
    </div>
  </div>
  );
};

function Store() {
  const { storeSlug } = useParams();

  const [activeCategory, setActiveCategory] = useState();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [store, setStore] = useState([]);
  const [cartItemsLoaded, setCartItemsLoaded] = useState(false);

  const { guestCustomer, setGuestCustomer, setCartCount, shoppingCart, setShoppingCart, orderTotals, getOrderTotals } = useContext(StoreContext);
  const [recommendedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState({ message: '', type: '' });
  const navigate = useNavigate();

  const setupGuestCustomer = async () => {
    let localGuestCustomer = localStorage.getItem('guestCustomer');
    if (localGuestCustomer === null) {
      const response = await sdk.createGuestCustomer();

      localGuestCustomer = Number(response.data.Id);
      localStorage.setItem('guestCustomer', localGuestCustomer);
    }
    return localGuestCustomer;
  }

  const showNotification = (message, type = 'info') => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000); // Hide notification after 3 seconds
  };

  const closeNotification = () => {
    setNotification({ message: '', type: '' }); // Manually close the notification
  };

  const init = async () => {
    try {
      const categoriesResponse = await sdk.getCategories();
      const defaultCategory = {
        Name: 'All Products',
        Id: -1,
      };
      setActiveCategory(defaultCategory);

      setCategories([defaultCategory, ...categoriesResponse.data]);

      let storeRes;
      try {
        // get store metadata 
        storeRes = await sdk.getStoreBySlug(storeSlug);
        setStore(storeRes.data);
      } catch (err) {
        if (err.code === 'ERR_BAD_REQUEST')
          navigate('/page-not-found');
      }

      // establish customer
      const localGuestCustomer = await setupGuestCustomer();
      setGuestCustomer(localGuestCustomer);

      // TODO: get categories

      // get products for store
      const productsResponse = await sdk.getProductsForStore(storeRes.data.id);
      setProducts(productsResponse.data);

      if (!cartItemsLoaded) {
        // get customer cart
        const cartResponse = await sdk.getCustomerCart(localGuestCustomer, storeRes.data.nop_store_id);
        setCartCount(cartResponse.data.Items.length)

        setShoppingCart(cartResponse.data)
        setCartItemsLoaded(true)

        // Fetch order totals
        await getOrderTotals(localGuestCustomer, storeRes.data.nop_store_id);
      }
    }
    catch (err) {
      console.error('Error initializing store:', err);
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    init();
  }, []);

  const popularProducts = products.filter(product => product.Tags.includes(18));
  const filteredProducts = products.filter(product => activeCategory.Id === -1 || product.Categories.includes(activeCategory.Id));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Notification message={notification.message} type={notification.type} onClose={closeNotification} />
      <main>
        <Routes>
          <Route path="" element={
            <ProductsDashboard
              store={store}
              categories={categories}
              popularProducts={popularProducts}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              filteredProducts={filteredProducts}
              showNotification={showNotification}
            />
          } />
          <Route path="products/:id/" element={
            <CartContainer>
              <ProductDetails handleClose={() => navigate(`/${storeSlug}/cart`)} />
            </CartContainer>
          } />
          <Route path="cart" element={
            <CartContainer>
              <CartDetails
                cartItems={shoppingCart}
                handleClose={() => navigate(`/${storeSlug}`)}
                handleCheckout={() => navigate(`checkout`)}
                setCartItems={setShoppingCart}
                nopStoreId={store.nop_store_id}
                showNotification={showNotification}
              />
            </CartContainer>
          } />
          <Route path="checkout" element={
            <CartContainer>
              <Payments 
                cartItems={shoppingCart}
                handleClose={() => navigate(`/${storeSlug}`)}
                customerId={guestCustomer}
                storeId={store.nop_store_id}
                storeSlug={storeSlug}
                showNotification={showNotification}
                orderTotals={orderTotals}
              />
            </CartContainer>
          } />
          <Route path="completion/:orderId" element={
            <CartContainer>
              <Completion
                handleClose={() => navigate(`/${storeSlug}`)}
                showNotification={showNotification}
              />
            </CartContainer>
          } />
          <Route path="acceptance/:orderId" element={
            <CartContainer>
              <Acceptance
                handleClose={() => navigate(`/${storeSlug}`)}
                showNotification={showNotification}
              />
            </CartContainer>
          } />
        </Routes>
      </main>
    </div>
  );
}

export default Store;
