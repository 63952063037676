import React, { useState } from 'react';
import CategorySection from './../CategorySection'; // Adjust the path as necessary


function Products({ store, activeCategory, setActiveCategory, filteredProducts, recommendedItems }) {
    return (
        <div>
            <h2>Shop by Category</h2>
            <div className="category-tabs">
                <button
                    className={activeCategory === 'all' ? 'active' : ''}
                    onClick={() => setActiveCategory('all')}
                >
                    All Products
                </button>
                <button
                    className={activeCategory === 'amenities' ? 'active' : ''}
                    onClick={() => setActiveCategory('amenities')}
                >
                    Amenities
                </button>
                <button
                    className={activeCategory === 'local-experiences' ? 'active' : ''}
                    onClick={() => setActiveCategory('local-experiences')}
                >
                    Local Experiences
                </button>
            </div>

            <CategorySection title="Shop by Category" items={filteredProducts} nopStoreId={store.nop_store_id} />
            <h2>Host-Recommended</h2>
            {/* <CategorySection title="Host-Recommended" items={recommendedItems} /> */}
        </div>
    )
}

export default Products;