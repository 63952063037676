import React, { createContext, useState, useEffect } from 'react';
import apiClient from '../tools/apiClient';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);

  useEffect(() => {
    if (token) {
      fetchUserData();
    }
  }, [token]);

  const fetchUserData = async () => {
    try {
      const response = await apiClient.get('/users/me');
      setUser(response.data);
      if (response.data.organizations && response.data.organizations.length > 0) {
        setOrganizationData(response.data.organizations[0]);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      logout();
    }
  };

  const login = (newToken) => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
    setOrganizationData(null);
  };

  return (
    <UserContext.Provider value={{ token, user, organizationData, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
