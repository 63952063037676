import React from 'react';
import './ShareButton.css';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'

import linkIcon from './../../../assets/mobile/blending-mode.svg';


const ShareButton = () => {
    const url = window.location.href;
    const title = document.title;
    const text = "Check out this page:";

    const handleShareClick = async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: title,
              text: text,
              url: url,
            });
            console.log('Successfully shared');
          } catch (error) {
            console.error('Error sharing:', error);
          }
        } else {
          alert('Web Share API is not supported in your browser.');
        }
      };

    return (
      <button onClick={handleShareClick} className='share-button inset-y-0 right-0 gap-3 px-2.5 items-center bg-accent-70'>
      <div className='flex items-center gap-2 text-lg font-light tracking-wide text-skin-inverted'>
        <div className='hidden lg:flex'>Share</div>
      <ArrowUpOnSquareIcon className="h-6 w-6" />
      </div>
      </button>
    );
};

export default ShareButton;
