import React, { createContext, useState, useEffect, useRef } from 'react';
import apiClient from '../tools/apiClient';

// Create the context
export const StoreContext = createContext();

// Create a provider component
export const StoreProvider = ({ children }) => {
  const [shoppingCart, setShoppingCart] = useState();
  const [cartCount, setCartCount] = useState(0);
  const [guestCustomer, setGuestCustomer] = useState(0);
  const [orderTotals, setOrderTotals] = useState(null);

  const updateShoppingCartItem = async (storeId, shoppingCartItem) => {
    try {

      // Make an API call to add an item to the shopping cart
      let response = await apiClient.patch('/orders/shopping-cart-item', {
        CustomerId: guestCustomer,
        StoreId: storeId,
        ShoppingCartItemId: shoppingCartItem.Id,
        Quantity: shoppingCartItem.Quantity
      });

      let warnings = response.data;
      let error = undefined;

      if(warnings) {
        if (!Array.isArray(warnings)) {
          warnings = [warnings];
        }


      }

      fetchCart(guestCustomer, storeId)

      return {
        warnings: warnings,
        error: error
      }

      
    } catch (error) {
      // console.error('Failed to update shopping cart item:', error);
      
      return {
        error: error
      }
    }
  };

  const addToCart = async (product, storeId) => {
    try {
      // Make an API call to add an item to the shopping cart
      let response = await apiClient.post('/orders/add-product-to-cart', {
        CustomerId: guestCustomer,
        StoreId: storeId,
        ProductId: product.Id,
        Quantity: 1,
        SelectedAttributes: product.selectedAttributes,
      });

      let res = response.data;
      let success = response.data.Success;
      let warnings = response.data.Warnings;


      if(!success) {
        if (!Array.isArray(warnings)) {
          warnings = [warnings];
        }

        warnings.forEach(element => {
          console.error('Failed to add item to cart:', element);
        });
      }

      fetchCart(guestCustomer, storeId)

      
    } catch (error) {
      console.error('Failed to add item to cart:', error);
    }
  };

  const fetchCart = async(customerId, storeId) => {
    const response = await apiClient.get(`/orders/get-shopping-cart/${customerId}/${storeId}`);
    setShoppingCart(response.data);
    setCartCount(response.data.Items ? response.data.Items.length : 0);
    // Fetch order totals after updating the cart
    await getOrderTotals(customerId, storeId);
  };

  const getOrderTotals = async (customerId, storeId) => {
    try {
      const response = await apiClient.get(`/orders/get-order-totals/${customerId}/${storeId}`);
      setOrderTotals(response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch order totals:', error);
      return null;
    }
  };

  useEffect(() => {
    //fetchSession();
  }, []);

  return (
    <StoreContext.Provider value={{ 
      cartCount, 
      setCartCount, 
      addToCart, 
      guestCustomer, 
      setGuestCustomer, 
      shoppingCart, 
      setShoppingCart, 
      updateShoppingCartItem,
      orderTotals,
      getOrderTotals
    }}>
      {children}
    </StoreContext.Provider>
  );
};
