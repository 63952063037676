import {
    PaymentElement,
    LinkAuthenticationElement
  } from '@stripe/react-stripe-js'
  import {useState} from 'react'
  import {useStripe, useElements} from '@stripe/react-stripe-js';
  import { Separator } from "@/components/ui/separator"
  import { Lock } from 'lucide-react'

  import sdk from './../../tools/sdk';
  
  export default function Payment({customerId, storeId, storeSlug, paymentIntentId }) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsLoading(true);

      // update customer email 
      // await sdk.updateCustomerDetails(customerId, {
      //   Email: email
      // }) 

      const response = await sdk.processOrder(customerId, storeId, {
        email,
        paymentIntentId,
      });
  
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/${storeSlug}/completion/${response.data.orderId}`,
        },
      });
      
  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
  
      setIsLoading(false);
    }
  
    return (
      <form id="payment-form" className='space-y-2 bg-accent-20 p-8 rounded-lg shadow' onSubmit={handleSubmit}>
        <LinkAuthenticationElement id="link-authentication-element"  className='pt-4'
          // Access the email value like so:
          onChange={(event) => {
           setEmail(event.value.email);
          }}
          //
          // Prefill the email field like so:
          // options={{defaultValues: {email: 'foo@bar.com'}}}
          />
        <PaymentElement id="payment-element" />
        <div className='flex justify-center py-4'>
          <button disabled={isLoading || !stripe || !elements} id="submit" className='solid-button w-1/2'>
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
            </span>
          </button>
        </div>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
        <Separator className="my-4 text-grey-500" />
        <div className='pt-4 text-center text-sm text-gray-500 flex items-center justify-center'>
          <Lock className="w-4 h-4 mr-2" />
          Secure Payments by Stripe, Inc.
          </div>
      </form>
    )
  }