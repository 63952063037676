import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { StoreProvider } from './contexts/StoreContext';

import './styles/globals.css';
import Home from './apps/Home';
import Admin from './apps/Admin';
import Store from './apps/Store';
import Register from 'screens/Register';
import Message from 'components/mobile/Message';
import('./App.css');


function App() {
  return (
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/page-not-found" element={<Message message={'This store doesn\'t exist.'} />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="/register/*" element={<Register />} />
            <Route path="/:storeSlug/*" element={<StoreProvider><Store /></StoreProvider>} /> 
          </Routes>
        </Router>
      </UserProvider>
  );
}

export default App;
