import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import CloseBar from '../CloseBar';
import FeeInfo from '../FeeInfo';
import AddToCartButton from '../AddToCartButton';
import './CartDetails.css';
import { StoreContext } from '../../../contexts/StoreContext';

function CartDetails({ cartItems, handleClose, handleCheckout, setCartItems, nopStoreId, showNotification }) {
    const { updateShoppingCartItem } = useContext(StoreContext);

    const [cartTotal, setCartTotal] = useState(0.00);

    useEffect(() => {
        if (cartItems) {
            const totalPrice = cartItems.Items.reduce((sum, item) => sum + item.SubTotalValue, 0);
            setCartTotal(totalPrice);
        }
    }, [cartItems]);

    const onClickQuantityButtonRemoveItem = (itemId) => {

        const itemCart = cartItems.Items.find(item => item.Id === itemId);
        console.log(itemCart)
        itemCart.Quantity = 0;
        updateShoppingCartItem(nopStoreId, itemCart).then(result => {
            if ((!result?.warnings || result?.warnings.length == 0) && !result?.error) {
                showNotification('Product has been removed from the cart.', 'info')
            }
            else {
                if (result?.warnings) {
                    result?.warnings.forEach(warning => {
                        showNotification(warning, 'info')
                    });
                }

                if (result?.error) {
                    showNotification(result?.error, 'error')
                }
            }

        }
        );
    };

    return (
        <div className="cart-details px-4 mb-8">
                <div className="content">
                    <CloseBar text={'Shopping Cart'} handleClose={handleClose}></CloseBar>
                    <div>
                        {cartItems && cartItems.Items.map((item, index) => (
                            <div className="cart-item" key={index}>
                                <div className="item-content">
                                    <div className='h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200'>
                                        <img src={'https://ncommerce.dev.greeter.store' + item.Picture.ImageUrl} alt={item.Picture.AlternateText} className="h-full w-full object-cover object-center" />
                                    </div>
                                    <div className="ml-4 flex flex-1 flex-col">
                                        <div className="item-details flex justify-between text-base font-medium">
                                            <h3 className='mr-3'>{item.ProductName}</h3>
                                            <p className='ml-4 text-4'>{item.UnitPrice}</p>
                                        </div>
                                        <p className="item-attribute-info mt-1 text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: item.AttributeInfo }} />
                                        <div className='flex flex-1 justify-between text-sm'>
                                            <p className='text-gray-500'>Qty: {item.Quantity}</p>
                                            <div className="flex">
                                                <a className='cursor-pointer p-0 font-bold text-2 text-red-500 hover:text-red-500' onClick={() => onClickQuantityButtonRemoveItem(item.Id)}>Remove</a>
                                            </div>
                                        </div>
                                        {/* Commenting out - intention to only use "Remove" button */}
                                        {/* <div className="item-quantity">
                                            <button className="quantity-button" onClick={() => onClickQuantityButtonMinus(item.Id)}>-</button>
                                            <input type="text" value={item.Quantity} readOnly />
                                            <button className="quantity-button" onClick={() => onClickQuantityButtonPlus(item.Id)}>+</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        ))}

                        <hr className="divider" />
                        <div className='product-fee-details'>
                            <FeeInfo text={'Total'} fee={'$' + cartTotal} />
                            <AddToCartButton text={'Checkout'} onClick={handleCheckout} />
                        </div>
                    </div>
                </div>
        </div>
    );
}


export default CartDetails;
