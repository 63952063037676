import React, { useState } from 'react';
import './AddStore.css';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../../tools/apiClient';

function AddStore({ onComplete }) {
    const [store, setStore] = useState({
        name: '',
        title: '',
        description: '',
        file: null
    });

    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStore(prev => ({
            ...prev,
            [name]: value
        }));

        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: null
            }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.match('image.*')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setStore(prev => ({
                    ...prev,
                    file: reader.result
                }));
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const validateForm = () => {
        let newErrors = {};
        if (!store.name) {
            newErrors.name = 'Property name is required.';
        }
        if (!store.title) {
            newErrors.title = 'Homepage title is required.';
        }
        if (!store.description) {
            newErrors.description = 'Homepage description is required.';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            setIsLoading(true);
            try {
                const response = await apiClient.post('/stores/create', store);
                
                console.log('API response:', response.data);

                if (typeof onComplete === 'function') {
                    onComplete(response.data); // Pass the API response data to onComplete
                } else {
                    navigate('/admin/stores');
                }
            } catch (error) {
                console.error('Error creating store:', error);
                let errorMessage = 'An unexpected error occurred. Please try again.';
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Server responded with error:', error.response.data);
                    errorMessage = `Server error: ${error.response.status}`;
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                    errorMessage = 'No response from server. Please check your internet connection.';
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error setting up request:', error.message);
                }
                setErrors(prev => ({ ...prev, submit: errorMessage }));
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="max-w-2xl mx-auto py-12">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Create a new store
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label htmlFor="name" className="">
                            Property Name
                        </label>
                        <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-70 sm:text-sm sm:leading-6"
                                value={store.name}
                                onChange={handleChange}
                            />
                            {errors.name && (
                                <div className="text-red-500 text-sm mt-1">
                                    {errors.name}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="title" className="">
                            Homepage Title
                        </label>
                        <div className="mt-2">
                            <input
                                id="title"
                                name="title"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-70 sm:text-sm sm:leading-6"
                                value={store.title}
                                onChange={handleChange}
                            />
                            {errors.title && (
                                <div className="text-red-500 text-sm mt-1">
                                    {errors.title}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="description" className="">
                            Homepage Description
                        </label>
                        <div className="mt-2">
                            <input
                                id="description"
                                name="description"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-70 sm:text-sm sm:leading-6"
                                value={store.description}
                                onChange={handleChange}
                            />
                            {errors.description && (
                                <div className="text-red-500 text-sm mt-1">
                                    {errors.description}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="file" className="">
                            Logo
                        </label>
                        <div className="mt-2">
                            <input
                                type="file"
                                id="file"
                                name="file"
                                className=""
                                onChange={handleFileChange}
                            />
                            {imagePreviewUrl && (
                                <div className="mt-2">
                                    <img src={imagePreviewUrl} alt="Image Preview" className="w-24 h-auto" />
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        {errors.submit && (
                            <div className="text-red-500 text-sm mt-1">
                                {errors.submit}
                            </div>
                        )}
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-accent-90 px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-accent-60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-70 disabled:opacity-50"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Creating...
                                </>
                            ) : (
                                'Create Store'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddStore;
