import React, { useState, useEffect } from 'react';
import CloseBar from '../CloseBar';
import CheckoutForm from '../../CheckoutForm'
import './Payments.css'
import CartContainer from '../CartContainer';

function Payments({ cartItems, handleClose, customerId, storeId, storeSlug, orderTotals }) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (cartItems && cartItems.Items.length > 0 && orderTotals) {
            setIsLoading(false);
        }
    }, [cartItems, orderTotals]);

    if (isLoading) {
        return <div>Loading...</div>;
    }
    
    return (
        <div className="payment-details">
            <CartContainer>
                <div className="content">
                    <CloseBar text={'Checkout & Payment'} handleClose={handleClose}></CloseBar>
                    {cartItems && orderTotals && (
                    <>
                        <div>
                            <div className='mb-4'>
                                <h2 className='text-lg font-semibold p-4 text-accent-100'>Order Summary</h2>
                            </div>
                            {cartItems.Items.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div className="payment-item">
                                        <div className="payment-item-content px-4">
                                            <div className="payment-item-details">
                                                <h3 className=''>{item.Quantity} x {item.ProductName}</h3>
                                                <div className="payment-item-attribute-info" dangerouslySetInnerHTML={{ __html: item.AttributeInfo }} />
                                            </div>
                                            <div>
                                                <p className=''>{(item.SubTotal)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                            <div>
                                <hr className="divider" />
                                <div className='flex justify-between my-4 px-4'>
                                    <p className='font-normal'>Subtotal:</p>
                                    <p className='font-normal'>{orderTotals.SubTotal}</p>
                                </div>
                                {orderTotals.TaxRates.map((taxRate, index) => (
                                    <div key={index} className='flex justify-between my-4 px-4'>
                                        <p className='font-normal'>Guest Fee: ({taxRate.Rate}%)</p>
                                        <p className='font-normal'>{taxRate.Value}</p>
                                    </div>
                                ))}
                                <div className='flex justify-between my-4 px-4'>
                                    <p className='font-semibold text-lg'>Total:</p>
                                    <p className='font-semibold text-lg'>{orderTotals.OrderTotal}</p>
                                </div>
                            </div>
                            <div className='payment-info text-sm p-4'>
                                <p>Please review your reservation details including the early check-in option you've chosen.</p>
                            </div>
                            <div className='payment-info p-4'>
                                <h2 className=''>Important Information</h2>
                                <p className='text-sm'>This service is not approved until you receive a 'Request Approved' email.</p>
                            </div>
                        </div>
                        <div className='payment-form py-8 max-w-md'>
                            <CheckoutForm customerId={customerId} storeId={storeId} storeSlug={storeSlug} cartTotal={orderTotals.OrderTotal} />   
                        </div>
                    </>
                    )}
                </div>
            </CartContainer>
        </div>
    )
}

export default Payments;