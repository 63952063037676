import React from 'react';
import './Message.css';

function Message({ message }) {
    return (
        <div className="message-details">
            <div className="container">
                <div className="content">
                    <h1>404 - Page Not Found</h1>
                    <p className="message-info">{message}</p>
                </div>
            </div>
        </div>
    );
}

export default Message;
