import React, { useEffect, useState } from 'react';
import config from 'config';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAdminStore } from '../../contexts/AdminStoreContext';
import { ArrowLeft, Clock } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Textarea } from "@/components/ui/textarea"

function ProductForm({ onComplete }) {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const { adminStore } = useAdminStore();

    const [product, setProduct] = useState({
        Name: '',
        Description: '',
        CategoryId: -1,
        Price: 0.0,
        AdvanceNotice: false,
        NotifyTeammates: false,
        IsRental: false,
        ImageUrl: null
    });

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await axios.get(`${config.apiEndpoint}/categories`);
            setCategories(response.data)
        }
        fetchCategories()
    }, []);


    const handleChange = (e) => {
        const target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name

        // if (name === "CategoryId") {
        //     value = parseInt(value, 10);
        // }

        // if (name == "Price" && value) {
        //     value = parseFloat(value);
        // }

        setProduct(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProduct(prev => ({
                    ...prev,
                    File: reader.result
                }));
                setImagePreviewUrl(URL.createObjectURL(file));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let storeId = adminStore?.id;

        // If no adminStore, try to get store ID from URL
        if (!storeId) {
            const urlMatch = location.pathname.match(/\/admin\/stores\/(\d+)/);
            if (urlMatch && urlMatch[1]) {
                storeId = parseInt(urlMatch[1], 10);
            } else {
                console.error('No store ID found in URL or context');
                return;
            }
        }

        try {
            const productData = {
                Name: product.Name,
                Description: product.Description,
                CategoryId: parseInt(product.CategoryId, 10),
                Price: parseFloat(product.Price),
                AdvanceNotice: product.AdvanceNotice,
                NotifyTeammates: product.NotifyTeammates,
                IsRental: product.IsRental,
                StoreId: storeId,
                ImageUrl: null,
                File: product.File
            };

            const response = await axios.post(`${config.apiEndpoint}/products/create`, productData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 || response.status === 201) {
                if (onComplete) {
                    onComplete();
                } else {
                    navigate(`/admin/stores/${storeId}/`);
                }
            }
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 p-4 md:p-8">
            <Card className="mx-auto max-w-4xl">
                <CardHeader className="flex flex-row items-center justify-between">
          <div className="flex items-center space-x-4">
            <Button variant="ghost" size="icon">
              <ArrowLeft className="h-6 w-6" />
            </Button>
            <CardTitle className="text-2xl font-bold">Create New Product</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="description" className="w-full">
            <TabsList className="grid w-full grid-cols-2 mb-6 min-h-12">
              <TabsTrigger value="description">Description</TabsTrigger>
              <TabsTrigger value="settings">Settings</TabsTrigger>
            </TabsList>
            
            <TabsContent value="description" className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Photo upload section - full width */}
                <div className="md:col-span-2 space-y-2">
                  <Label htmlFor="productImage">Edit Photo</Label>
                  <div className="border-2 border-dashed border-gray-200 rounded-lg p-6 text-center">
                    {imagePreviewUrl ? (
                      <div className="relative w-full h-48">
                        <img 
                          src={imagePreviewUrl} 
                          alt="Preview" 
                          className="object-cover w-full h-full rounded-lg"
                        />
                        <Button 
                          variant="secondary" 
                          size="sm" 
                          className="absolute bottom-2 right-2"
                        >
                          Change Photo
                        </Button>
                      </div>
                    ) : (
                      <>
                        <div className="mt-2 flex text-sm text-gray-600">
                          <label className="outline-button relative cursor-pointer rounded-md font-medium text-primary hover:text-primary/80">
                            <span>Upload a file</span>
                            <Input 
                              id="productImage" 
                              name="productImage" 
                              type="file" 
                              accept="image/*"
                              className="sr-only"
                              onChange={handleFileChange}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </>
                    )}
                  </div>
                </div>

                {/* Title and Subtitle in first column */}
                <div className="space-y-2">
                  <Label htmlFor="title">Title</Label>
                  <Input 
                    id="title" 
                    name="Name"
                    value={product.Name}
                    onChange={handleChange}
                    placeholder="e.g., Mid-Stay Cleaning" 
                  />
                </div>

                {/* Price in second column */}
                <div className="space-y-2">
                  <Label htmlFor="fee">Fee</Label>
                  <div className="relative">
                    <span className="absolute left-3 top-2.5">$</span>
                    <Input 
                      id="fee"
                      name="Price"
                      type="number"
                      min="0"
                      step="0.01"
                      value={product.Price}
                      onChange={handleChange}
                      className="pl-7"
                      placeholder="125"
                    />
                  </div>
                  <p className="text-sm text-muted-foreground">You make: ${(product.Price * 0.93).toFixed(2)}</p>
                </div>

                {/* Description - full width */}
                <div className="md:col-span-2 space-y-2">
                  <Label htmlFor="description">Description</Label>
                  <Textarea 
                    id="description"
                    name="Description"
                    value={product.Description}
                    onChange={handleChange}
                    placeholder="e.g., Come back to a sparkling clean home..."
                    className="min-h-[100px]"
                  />
                </div>

                {/* Category selection - full width */}
                <div className="md:col-span-2 space-y-2">
                  <Label htmlFor="category">Category</Label>
                  <select
                    id="CategoryId"
                    name="CategoryId"
                    value={product.CategoryId}
                    onChange={handleChange}
                    className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                  >
                    <option value="-1">Select Category</option>
                    {categories.map(category => (
                      <option key={category.Id} value={category.Id}>
                        {category.Name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </TabsContent>
            
            <TabsContent value="settings" className="space-y-8">
              <div className="space-y-6">
                {/* Advance Notice Section */}
                <div className="border rounded-lg p-4 space-y-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-lg font-semibold">Advance Notice</h3>
                      <p className="text-sm text-muted-foreground">Require approval before confirming bookings</p>
                    </div>
                    <Switch 
                      id="AdvanceNotice"
                      name="AdvanceNotice"
                      checked={product.AdvanceNotice}
                      onCheckedChange={(checked) => setProduct(prev => ({ ...prev, AdvanceNotice: checked }))}
                      className="justify-start"
                    />
                  </div>
                </div>

                {/* Team Notifications Section */}
                <div className="border rounded-lg p-4 space-y-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-lg font-semibold">Team Notifications</h3>
                      <p className="text-sm text-muted-foreground">Notify team members of new bookings</p>
                    </div>
                    <Switch 
                      id="NotifyTeammates"
                      name="NotifyTeammates"
                      checked={product.NotifyTeammates}
                      onCheckedChange={(checked) => setProduct(prev => ({ ...prev, NotifyTeammates: checked }))}
                      className="justify-start"
                    />
                  </div>
                </div>

                {/* Rental Option Section */}
                <div className="border rounded-lg p-4 space-y-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-lg font-semibold">Rental Item</h3>
                      <p className="text-sm text-muted-foreground">Mark this as a rental product</p>
                    </div>
                    <Switch 
                      id="IsRental"
                      name="IsRental"
                      checked={product.IsRental}
                      onCheckedChange={(checked) => setProduct(prev => ({ ...prev, IsRental: checked }))}
                      className="justify-start"
                    />
                  </div>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
        <CardFooter>
          <Button 
            onClick={handleSubmit}
            className="w-full bg-green-500 hover:bg-green-600 py-6 text-lg"
          >
            Create New Product
          </Button>
        </CardFooter>
            </Card>
        </div>
    );
}

export default ProductForm;
