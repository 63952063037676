import React from 'react';
import './FeeInfo.css';

const FeeInfo = ({ text, fee }) => {
  return (
    <div className="fee-info">
      <span className="fee-label text-skin-accent text-5 font-bold">{text}:</span>
      <span className="fee-amount px-4 text-5">{fee}</span>
    </div>
  );
};

export default FeeInfo;
