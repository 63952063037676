import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../../contexts/StoreContext';
import ShareButton from 'components/mobile/ShareButton';
import CartButton from 'components/mobile/CartButton';
import ProductCards from 'components/mobile/ProductCards';
import FooterMobile from '../FooterMobile';

function ProductsDashboard({
  store,
  categories,
  popularProducts,
  activeCategory,
  setActiveCategory,
  filteredProducts,
}) {

  const navigate = useNavigate();
  const { cartCount } = useContext(StoreContext);

  useEffect(() => {
    console.log('Cart count:', cartCount);
  }, [cartCount]);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  return (
    <div>
      <header className="store-header rounded-b py-4 px-2 bg-accent-10">
            <div className='flex flex-row p-2 gap-2'>
              <img src="https://supergreeter-product-resources.s3.amazonaws.com/companyName/email-resources/SuperGreeter-hands-sml.png" class="h-10 mx-4" alt="SuperGreeter Hands Logo" />
              <div className='flex flex-row gap-4 justify-end w-full'>
                <CartButton count={cartCount} />  
                <div className=''><ShareButton /></div>
              </div>
            </div>
      </header>

      <div className="content mx-auto">
        <div className='lg:flex lg:flex-row shadow-md bg-accent-10'>
          <div className='lg:max-w-screen-md xl:max-w-screen-lg mx-auto lg:flex lg:grow'>
            <div className="property-logo lg:p-4">
              <div className='flex justify-center items-center p-4'>
                <img src={store.logoUrl} alt="Property Logo" className='w-64 h-64 rounded-full object-cover' />
              </div>
            </div>
            <div className='lg:flex lg:flex-col place-content-center pb-4'>
              <div className='flex justify-center lg:justify-start'>
                <div className="property-name text-skin-base text-7 font-bold p-3 text-center">
                  {store.name}
                </div>
              </div>
              <div className="property-info p-3 grid-cols-2 gap-4">
                <div className='justify-center flex flex-col shrink'>
                  <div className="property-welcome text-4 text-skin-base text-center lg:text-left">
                    {store.title}
                  </div>
                  <div className="text-skin-base font-light text-3 mt-2 text-center lg:text-left">
                    {store.description}
                  </div>
                </div>          
              </div>
          </div>
        </div>
          
        </div>

        <div className='lg:max-w-screen-md xl:max-w-screen-lg mx-auto'>
        <div className='m-4'>
          <h2 className="px-4 py-2 text-6 font-bold text-skin-base">Popular</h2>
            
            <div className="products-scroll-container">
                {popularProducts.map((product) => (
                  <ProductCards key={product.id} product={product} />
                ))}
            </div>

          </div>
          <div className='bg-white mx-4 pt-4'>
            <h2 className="px-4 py-2 text-6 font-bold text-skin-base">Explore by Category</h2>

            <ul className="category-scroll-container p-2 flex flex-nowrap -mb-px" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
              {
              categories.map((category) => (
              <li key={category.Id} className='m-1 me-1 mx-2 rounded border-b-2 has-[.active-tabs]:border-accent-100'>
                <button
                  className={`text-skin-base font-normal whitespace-nowrap ${category.Id === activeCategory?.Id ? 'tabs active-tabs px-2' : 'tabs text-skin-muted'}`}
                  onClick={() => handleCategoryChange(category)}
                >
                  {category.Name}
                </button>
              </li>
              ))}
            </ul>

            <div className="products-scroll-container">
              {filteredProducts.map((product) => (
                <ProductCards key={product.id} product={product} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <FooterMobile />
    </div>
  )

}

export default ProductsDashboard;
