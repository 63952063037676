import apiClient from './apiClient';

const sdk = {
    createGuestCustomer: async () => {
        return apiClient.get('/customers/create-guest-customer');
    },

    updateCustomerDetails: async (customerId, customer) => {
        return apiClient.post(`/customers/update-guest-customer/${customerId}`, { ...customer });
    },

    getStoreBySlug: async (storeSlug) => {
        return apiClient.get(`/stores/${storeSlug}`);
    },

    getCategories: async () => {
        return apiClient.get(`/categories/`);
    },

    getProductForStore: async (storeId, productId) => {
        return apiClient.get(`/products/${storeId}/${productId}`);
    },

    getProductsForStore: async (storeId) => {
        return apiClient.get(`/products/${storeId}`);
    },

    getCustomerCart: async (customerId, storeId) => {
        return apiClient.get(`/orders/get-shopping-cart/${customerId}/${storeId}`)
    },

    addProductToCart: async () => {

    },

    processOrder: async (customerId, storeId, paymentInfo) => {
        return apiClient.post(`/orders/process-checkout/${customerId}/${storeId}`, { ...paymentInfo })
    },

    getOrderDetails: async(orderId) => {
        return apiClient.get(`/orders/get-order-details/${orderId}`)
    },
 
    updateOrderPaidStatus: async(paymentIntentClientSecret, orderId, orderStatus) => {
        return apiClient.post(`/orders/update-order-paid-status/${paymentIntentClientSecret}/${orderId}`, { ...orderStatus })
    },

    createPaymentIntent: async (amount) => {
        return apiClient.post(`/orders/create-payment-intent/`, { amount })
    }
};

export default sdk;
