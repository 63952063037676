import { React, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { CheckCircle2 } from "lucide-react"
import onboardingSteps from '../../../../src/config/onboardingConfig';

function WelcomeScreen() {
    const navigate = useNavigate();
  
    const handleContinue = () => {
      navigate('/admin/onboarding/add-store');
    };
  
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Card className="w-full max-w-lg shadow-lg">
          <CardHeader>
            <CardTitle className="text-3xl font-bold text-center text-primary">Welcome to SuperGreeter!</CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            <p className="text-center text-lg text-muted-foreground">
              You're just steps away from creating unforgettable experiences for your guests!
            </p>
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-primary">What's Next?</h3>
              {onboardingSteps.map((step, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <CheckCircle2 className="text-green-500" />
                  <span>{step}</span>
                </div>
              ))}
            </div>
          </CardContent>
          <CardFooter>
            <Button onClick={handleContinue} className="w-full">
              Let's Get Started
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }

export default WelcomeScreen