import React from 'react';
import { useParams } from 'react-router-dom';
import './ProductForm.css';
import ProductForm from '../../ProductForm';
import Breadcrumbs from '../Breadcrumbs';
import onboardingSteps from '../../../../src/config/onboardingConfig';


function AddProduct({ storeId, onComplete }) {
    const { storeSlug } = useParams();
    return (
        <div className="App">
            <ProductForm storeSlug={storeSlug} storeId={storeId} onComplete={onComplete}/>
        </div>
    );
}

export default AddProduct;