import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CloseBar from '../CloseBar';
import AddToCartButton from '../AddToCartButton'
import './Completion.css';
import sdk from 'tools/sdk';
import CartContainer from '../CartContainer';

const Completion = ({ handleClose }) => {
    const [transactionDetails, setTransactionDetails] = useState(null);
    const location = useLocation();
    const { orderId } = useParams();

    const init = async () => {
        const query = new URLSearchParams(location.search);
        const paymentIntent = query.get('payment_intent');
        const paymentIntentClientSecret = query.get('payment_intent_client_secret');
        
        const paymentStatus = query.get('redirect_status');
        let status = '';
        if (paymentStatus === 'succeeded') {
            status = 'processing'
        }

        if (paymentIntent && paymentStatus) {
            const response = await sdk.updateOrderPaidStatus(paymentIntentClientSecret, orderId, {
                status: status
            })
    
            const date = new Date().toISOString(); // You would get this from your backend

            
            setTransactionDetails({
                id: paymentIntent,
                amount: response.data.OrderTotal,
                date: date,
                status: paymentStatus,
            });
        }
    }

    useEffect(() => {
        init()
    }, [location]);



    if (!transactionDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="payment-details mb-8">
            <CartContainer>
                <CloseBar text={'Transaction Details'} handleClose={handleClose}></CloseBar>
                <div className="content mx-4">
                    <div className="completion-message">
                        <h1>Payment Successful!</h1>
                        <p>Thank you for your purchase.</p>
                        <p>A confirmation email has been sent to you.</p>
                    </div>
                    <div className="transaction-details">
                        <h2>Transaction Details</h2>
                        <p><strong>Transaction ID:</strong> {transactionDetails.id}</p>
                        <p><strong>Amount:</strong> ${(transactionDetails.amount).toFixed(2)}</p>
                        <p><strong>Date:</strong> {new Date(transactionDetails.date).toLocaleDateString()}</p>
                        <p><strong>Status:</strong> {transactionDetails.status}</p>
                    </div>
                    <div className='product-fee-details'>
                            <AddToCartButton text={'Got It!'} onClick={handleClose} />

                    </div>
                </div>
            </CartContainer>
        </div>

    );
};

export default Completion;
