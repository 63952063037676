import { useState } from "react"
import { Link } from "lucide-react"
import { Input } from "@/components/ui/input"
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuRadioGroup, DropdownMenuRadioItem } from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table"
import { Badge } from "@/components/ui/badge"

export default function Component() {
  const [search, setSearch] = useState("")
  const [sort, setSort] = useState({ key: "order", order: "asc" })
  const [filters, setFilters] = useState({
    status: [],
    channel: [],
  })
  const handleSearch = (e) => setSearch(e.target.value)
  const handleSort = (key) => {
    if (sort.key === key) {
      setSort({ key, order: sort.order === "asc" ? "desc" : "asc" })
    } else {
      setSort({ key, order: "asc" })
    }
  }
  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: prev[type].includes(value) ? prev[type].filter((item) => item !== value) : [...prev[type], value],
    }))
  }
  const orders = [
    {
      order: "#3210",
      customer: "Olivia Martin",
      channel: "Online Store",
      date: "February 20, 2022",
      total: 42.25,
      status: "Shipped",
    },
    {
      order: "#3209",
      customer: "Ava Johnson",
      channel: "Shop",
      date: "January 5, 2022",
      total: 74.99,
      status: "Paid",
    },
    {
      order: "#3204",
      customer: "Michael Johnson",
      channel: "Shop",
      date: "August 3, 2021",
      total: 64.75,
      status: "Unfulfilled",
    },
    {
      order: "#3203",
      customer: "Lisa Anderson",
      channel: "Online Store",
      date: "July 15, 2021",
      total: 34.5,
      status: "Shipped",
    },
    {
      order: "#3202",
      customer: "Samantha Green",
      channel: "Shop",
      date: "June 5, 2021",
      total: 89.99,
      status: "Paid",
    },
    {
      order: "#3201",
      customer: "Adam Barlow",
      channel: "Online Store",
      date: "May 20, 2021",
      total: 24.99,
      status: "Unfulfilled",
    },
    {
      order: "#3207",
      customer: "Sophia Anderson",
      channel: "Shop",
      date: "November 2, 2021",
      total: 99.99,
      status: "Paid",
    },
    {
      order: "#3206",
      customer: "Daniel Smith",
      channel: "Online Store",
      date: "October 7, 2021",
      total: 67.5,
      status: "Shipped",
    },
  ]
  const filteredOrders = orders.filter((order) => {
    const searchValue = search.toLowerCase()
    return (
      order.order.toLowerCase().includes(searchValue) ||
      order.customer.toLowerCase().includes(searchValue) ||
      order.channel.toLowerCase().includes(searchValue) ||
      order.date.toLowerCase().includes(searchValue) ||
      order.total.toString().includes(searchValue) ||
      order.status.toLowerCase().includes(searchValue)
    )
  })
  const sortedOrders = filteredOrders.sort((a, b) => {
    if (sort.order === "asc") {
      return a[sort.key] > b[sort.key] ? 1 : -1
    } else {
      return a[sort.key] < b[sort.key] ? 1 : -1
    }
  })
  return (
    <div className="min-h-screen w-full overflow-hidden">
      {/* <div className="hidden border-r bg-muted/40 lg:block">
        <div className="flex flex-col gap-2">
          <div className="flex h-[60px] items-center px-6">
            <Link href="#" className="flex items-center gap-2 font-semibold" prefetch={false}>
              <Package2Icon className="h-6 w-6" />
              <span className="">Acme Inc</span>
            </Link>
          </div>
          <div className="flex-1">
            <nav className="grid items-start px-4 text-sm font-medium">
              <Link
                href="#"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"
                prefetch={false}
              >
                <HomeIcon className="h-4 w-4" />
                Home
              </Link>
              <Link
                href="#"
                className="flex items-center gap-3 rounded-lg bg-muted px-3 py-2 text-primary  transition-all hover:text-primary"
                prefetch={false}
              >
                <ShoppingCartIcon className="h-4 w-4" />
                Orders{" "}
                <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
                  {sortedOrders.length}
                </Badge>
              </Link>
              <Link
                href="#"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"
                prefetch={false}
              >
                <PackageIcon className="h-4 w-4" />
                Products
              </Link>
              <Link
                href="#"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"
                prefetch={false}
              >
                <UsersIcon className="h-4 w-4" />
                Customers
              </Link>
              <Link
                href="#"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"
                prefetch={false}
              >
                <LineChartIcon className="h-4 w-4" />
                Analytics
              </Link>
            </nav>
          </div>
        </div>
      </div> */}
      <div className="flex flex-col p-8">
        <h1 className="text-3xl font-bold mb-6">Recent Orders</h1>
        <div className="flex flex-1 items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <form className="ml-auto flex-1 sm:flex-initial">
              <div className="relative">
                <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search orders..."
                  className="pl-8 sm:w-[300px] md:w-[2f00px] lg:w-[300px]"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </form>
          </div>
        <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6">
          <div className="border shadow-sm rounded-lg p-2 relative overflow-hidden">
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
              <Badge className="text-sm font-light py-2 px-4" variant="secondary">Coming Soon</Badge>
            </div>
            <div className="flex flex-col md:flex-row items-start md:items-center gap-4 mb-4">
              <div className="flex items-center gap-2">
                <Button
                  variant={filters.status.includes("Shipped") ? "secondary" : "outline"}
                  onClick={() => handleFilterChange("status", "Shipped")}
                >
                  Shipped
                </Button>
                <Button
                  variant={filters.status.includes("Paid") ? "secondary" : "outline"}
                  onClick={() => handleFilterChange("status", "Paid")}
                >
                  Paid
                </Button>
                <Button
                  variant={filters.status.includes("Unfulfilled") ? "secondary" : "outline"}
                  onClick={() => handleFilterChange("status", "Unfulfilled")}
                >
                  Unfulfilled
                </Button>
              </div>
              {/* <div className="flex items-center gap-2">
                <Button
                  variant={filters.channel.includes("Online Store") ? "secondary" : "outline"}
                  onClick={() => handleFilterChange("channel", "Online Store")}
                >
                  Online Store
                </Button>
                <Button
                  variant={filters.channel.includes("Shop") ? "secondary" : "outline"}
                  onClick={() => handleFilterChange("channel", "Shop")}
                >
                  Shop
                </Button>
              </div> */}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="ml-auto shrink-0">
                    <ArrowUpDownIcon className="w-4 h-4 mr-2" />
                    Sort by
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[200px] bg-white" align="end">
                  <DropdownMenuRadioGroup value={sort.key}>
                    <DropdownMenuRadioItem value="order" onClick={() => handleSort("order")}>
                      Order
                      {sort.key === "order" && (
                        <span className="ml-1">{sort.order === "asc" ? "\u2191" : "\u2193"}</span>
                      )}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="customer" onClick={() => handleSort("customer")}>
                      Customer
                      {sort.key === "customer" && (
                        <span className="ml-1">{sort.order === "asc" ? "\u2191" : "\u2193"}</span>
                      )}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="date" onClick={() => handleSort("date")}>
                      Date
                      {sort.key === "date" && (
                        <span className="ml-1">{sort.order === "asc" ? "\u2191" : "\u2193"}</span>
                      )}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="total" onClick={() => handleSort("total")}>
                      Total
                      {sort.key === "total" && (
                        <span className="ml-1">{sort.order === "asc" ? "\u2191" : "\u2193"}</span>
                      )}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="status" onClick={() => handleSort("status")}>
                      Status
                      {sort.key === "status" && (
                        <span className="ml-1">{sort.order === "asc" ? "\u2191" : "\u2193"}</span>
                      )}
                    </DropdownMenuRadioItem>
                  </DropdownMenuRadioGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">Order</TableHead>
                  <TableHead className="min-w-[150px]">Customer</TableHead>
                  <TableHead className="hidden md:table-cell">Channel</TableHead>
                  <TableHead className="hidden md:table-cell">Date</TableHead>
                  <TableHead className="text-right">Total</TableHead>
                  <TableHead className="hidden sm:table-cell">Status</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedOrders.map((order) => (
                  <TableRow key={order.order}>
                    <TableCell className="font-medium">{order.order}</TableCell>
                    <TableCell>{order.customer}</TableCell>
                    <TableCell className="hidden md:table-cell">{order.channel}</TableCell>
                    <TableCell className="hidden md:table-cell">{order.date}</TableCell>
                    <TableCell className="text-right">${order.total.toFixed(2)}</TableCell>
                    <TableCell className="hidden sm:table-cell">{order.status}</TableCell>
                    <TableCell className="text-right">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" size="icon">
                            <MoveHorizontalIcon className="w-4 h-4" />
                            <span className="sr-only">Actions</span>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem>View order</DropdownMenuItem>
                          <DropdownMenuItem>Customer details</DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </main>
      </div>
    </div>
  )
}

function ArrowUpDownIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m21 16-4 4-4-4" />
      <path d="M17 20V4" />
      <path d="m3 8 4-4 4 4" />
      <path d="M7 4v16" />
    </svg>
  )
}

function MoveHorizontalIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="18 8 22 12 18 16" />
      <polyline points="6 8 2 12 6 16" />
      <line x1="2" x2="22" y1="12" y2="12" />
    </svg>
  )
}


function Package2Icon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z" />
      <path d="m3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9" />
      <path d="M12 3v6" />
    </svg>
  )
}

function SearchIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  )
}