import React, { useState, useEffect } from 'react';
import apiClient from '../../../tools/apiClient';
import { Card, CardContent } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { useNavigate, useParams } from 'react-router-dom';

const ProductItem = ({ item, onSelect, disabled }) => (
  <Card className={`overflow-hidden ${disabled ? 'opacity-50' : ''}`}>
    <img src={item.ImageUrl || "/placeholder.svg"} alt={item.Name} className="w-full h-48 object-cover" />
    <CardContent className="p-4">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h3 className="font-semibold">{item.Name}</h3>
          <p className="text-sm text-muted-foreground">${item.Price}</p>
        </div>
        <Checkbox 
          className="w-6 h-6"
          checked={item.selected} 
          onCheckedChange={() => !disabled && onSelect(item.Id)}
          disabled={disabled}
        />
      </div>
      <p className="text-sm text-muted-foreground">{item.ShortDescription}</p>
    </CardContent>
  </Card>
)

const ProductGrid = ({ items, onSelect }) => {
  const hasSelectedProduct = items.some(item => item.selected);
  
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {items.length > 0 ? (
        items.map(item => (
          <ProductItem 
            key={item.Id} 
            item={item} 
            onSelect={onSelect}
            disabled={hasSelectedProduct && !item.selected}
          />
        ))
      ) : (
        <div>No products found for this category.</div>
      )}
    </div>
  );
}

function SelectProduct({ onComplete }) {
  const navigate = useNavigate();
  const { storeId } = useParams();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {
      try {
        const categoriesResponse = await apiClient.get('/categories');
        setCategories(categoriesResponse.data);

        const productsResponse = await apiClient.get('/products/defaults');
        const fetchedProducts = productsResponse.data.map(product => ({
          ...product,
          selected: false
        }));
        setProducts(fetchedProducts);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load categories and products. Please try again.');
        setLoading(false);
      }
    };

    fetchCategoriesAndProducts();
  }, []);

  const handleSelect = (id) => {
    setProducts(prevProducts => {
      const updatedProducts = prevProducts.map(item =>
        item.Id === id ? { ...item, selected: !item.selected } : item
      );
      return updatedProducts.sort((a, b) => {
        if (a.selected === b.selected) return 0;
        return a.selected ? -1 : 1;
      });
    });
  }

  const handleContinue = () => {
    const selectedProduct = products.find(product => product.selected);
    const imageUrl = selectedProduct.ImageUrl || "/placeholder.svg";

    if (selectedProduct) {
      navigate(`/admin/stores/${storeId}/products/confirm-new-product`, {
        state: { selectedProduct, imageUrl }
      });
    }
  }

  if (loading) return <div>Loading categories and products...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Select Products and Services</h1>

      <Tabs defaultValue="all" className="w-full">
        <TabsList className="grid w-full grid-cols-5 mb-8 h-full">
          <TabsTrigger value="all">All Categories</TabsTrigger>
          {categories.map(category => (
            <TabsTrigger key={category.Id} value={category.Name}>{category.Name}</TabsTrigger>
          ))}
        </TabsList>
        <TabsContent value="all">
          <ProductGrid 
            items={products} 
            onSelect={handleSelect} 
          />
        </TabsContent>
        {categories.map(category => {
          const categoryProducts = products.filter(product => product.Categories.includes(category.Id));
          return (
            <TabsContent key={category.Id} value={category.Name}>
              <ProductGrid 
                items={categoryProducts} 
                onSelect={handleSelect} 
              />
            </TabsContent>
          );
        })}
      </Tabs>

      <div className="mt-8 text-center">
        <Button onClick={handleContinue} className="bg-primary hover:bg-primary/90 text-primary-foreground">
          Continue to Product Details
        </Button>
      </div>
    </div>
  )
}

export default SelectProduct;